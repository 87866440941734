import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { IMetric } from '@models/metrics/metric';

/**
 * Component to display progress
 *
 * @selector progress-bar
 * @standalone true
 * @component ProgressComponent
 * @imports CommonModule
 *
 * @Input metric - Object conforming to IMetric interface, representing the progress.
 * @Input overall - The summary value for the data.
 * @Input label - Text to display.
 * @Input percentage - Display the % symbol
 *
 * Usage:
 * <progressbar [metric]="data" [overall]="overall" [label]="SLA" [percentage]="true"></progressbar>
 *
 * Note:
 * A data item contains the percent value of the progress bar and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [25, 75]}
 * In this scenario a percent value over 25 will render amber state and over 75 will render red state.
 *
 * e.g. { percent: 30, state: [50]}
 * In this scenario a percent value over 50 will render amber state. No red state is configured.
 *
 */
@Component({
    selector: 'progressbar',
    standalone: true,
    imports: [CommonModule, NgbProgressbarModule],
    templateUrl: './progress.component.html',
    styleUrl: './progress.component.scss',
})
export class ProgressComponent implements OnChanges {
    progress = 0;
    state = 'success';
    @Input({ required: true }) label!: string;
    @Input({ required: true }) overall!: string;
    @Input() metric: IMetric | undefined;
    @Input() percentage = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['metric'] && this.metric) {
            this.progress = this.metric.percent;
            this.state = this.translateColour(this.metric);
        }
    }

    private translateColour(value: IMetric): string {
        if (
            value &&
            value.state &&
            value.state.length == 2 &&
            value.percent >= value.state[1]
        ) {
            return 'danger';
        }
        if (
            value &&
            value.state &&
            value.state.length > 0 &&
            value.percent >= value.state[0]
        ) {
            return 'warning';
        }

        return 'success';
    }
}
