import { IMetric } from '@models/metrics/metric';

export const findStateForMetric = (
    metric: IMetric,
    defaultState: string,
    emptyState: string
): string => {
    if (metric.states.length === 0) return emptyState;

    const sortedStates = metric.states.sort((a, b) => a.value - b.value);

    for (let i = sortedStates.length - 1; i >= 0; i--) {
        if (metric.percent >= sortedStates[i].value) {
            return sortedStates[i].state;
        }
    }

    return defaultState;
};

export const toPercentage = (value?: number, defaultValue = 0): number => {
    return Math.round((value ?? defaultValue) * 100);
};
