import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    INavBarDataModel,
    collapsedNavbarData,
    navbarData,
} from '@models/main/nav-data';
import { SideNavItemComponent } from '@components/side-nav-item/side-nav-item.component';
import { ReplaceSpacesPipe } from '@pipes/replace-spaces/replace-spaces.pipe';

@Component({
    selector: 'side-nav',
    standalone: true,
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
    imports: [
        CommonModule,
        RouterModule,
        SideNavItemComponent,
        ReplaceSpacesPipe,
    ],
})
export class SideNavComponent {
    @Input({ required: true }) isCollapsed!: boolean;
    @Output() toggleCollapse: EventEmitter<void> = new EventEmitter();

    onToggleCollapse() {
        this.toggleCollapse.emit();
    }

    get navbarData(): INavBarDataModel[] {
        return this.isCollapsed ? collapsedNavbarData : navbarData;
    }
}
