import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'task-belt-item',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './task-belt-item.component.html',
    styleUrl: './task-belt-item.component.scss',
})

export class TaskBeltItemComponent {

    @Input()  taskName = '';
    @Input()  description = '';
    @Input()  createdAt = '';
    @Input()  icon = '';
    @Input()  iconSelected = '';
    @Input()  order = 0;
    @Input()  isSelected = false;
    @Input()  selectedClass = '';
    @Input()  removing = false;
    @Input()  slideClass = '';
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    onClick() {
        this.clicked.emit();
    } 

    
    
    

}
