<div class="container h-100">
    <div class="row h-100">
        <div class="col-11">
            <div class="container">
                <div class="row">
                    <div class="col-3">
                        <rag-panel
                            title="Desired SLA Percentage"
                            [ragPanelType]="RagPanelType.Percentage"
                            [lowValue]="telXLThreshold?.slaThresholdLow ?? null"
                            [highValue]="
                                telXLThreshold?.slaThresholdHigh ?? null
                            "
                            [min]="0"
                            [max]="100"
                            (valueChange)="onSlaThresholdChange($event)"
                        />
                    </div>

                    <div class="col-3">
                        <rag-panel
                            title="Current Waiting Time"
                            [ragPanelType]="RagPanelType.Time"
                            [lowValue]="
                                telXLThreshold?.maxLongestWaitTimeLowSeconds ??
                                null
                            "
                            [highValue]="
                                telXLThreshold?.maxLongestWaitTimeHighSeconds ??
                                null
                            "
                            (valueChange)="
                                onMaxLongestWaitTimeSecondsChange($event)
                            "
                        />
                    </div>
                    <div class="col-3">
                        <rag-panel
                            title="Waiting"
                            [ragPanelType]="RagPanelType.Numeric"
                            [lowValue]="
                                telXLThreshold?.maxConversationsLowWaiting ??
                                null
                            "
                            [highValue]="
                                telXLThreshold?.maxConversationsHighWaiting ??
                                null
                            "
                            [min]="0"
                            [max]="10"
                            (valueChange)="
                                onMaxConversationsWaitingChange($event)
                            "
                        />
                    </div>
                    <div class="col-3">
                        <rag-panel
                            title="Wait Time Today Average"
                            [ragPanelType]="RagPanelType.Time"
                            [lowValue]="
                                telXLThreshold?.averageWaitTimeLowSeconds ??
                                null
                            "
                            [highValue]="
                                telXLThreshold?.averageWaitTimeHighSeconds ??
                                null
                            "
                            (valueChange)="
                                onAverageWaitTimeSecondsChange($event)
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-1 d-flex flex-column align-items-center justify-content-center"
        >
            <button
                (click)="save()"
                [disabled]="!canSave"
                class="btn btn-sm btn-primary-outline w-100 mb-2"
            >
                Save
            </button>
            <button (click)="close()" class="btn btn-sm btn-primary w-100">
                Cancel
            </button>
        </div>
    </div>
</div>
