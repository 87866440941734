import { Pipe, PipeTransform } from '@angular/core';
import { ITimeDifference } from '@helpers/time';

@Pipe({
    name: 'formatDuration',
    standalone: true,
})
export class FormatDurationPipe implements PipeTransform {
    transform(value: ITimeDifference, alwaysShowHours = false): string {
        const hours = this.padZero(value.hours);
        const minutes = this.padZero(value.minutes);
        const seconds = this.padZero(value.seconds);

        if (alwaysShowHours) {
            return `${hours}:${minutes}:${seconds}`;
        } else {
            return `${value.hours > 0 ? hours + ':' : ''}${minutes}:${seconds}`;
        }
    }

    private padZero(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }
}
