import { Injectable, signal } from '@angular/core';
import { differenceInSeconds } from 'date-fns';
import { environment } from '@core/environments/environment';
import { NBU_ID } from '@models/business-unit/business-unit';
import { Queue } from '@models/queue/queue';
import { IQueueInformation, IQueueReport } from '@models/queue/queue';
import { IKpiThreshold, ITelXLKpiThreshold } from '@models/rbac/kpi';
import { BusinessUnitService } from '@services/business-unit/business-unit.service';
import { LoggerService } from '@services/logger/logger.service';

interface CacheHandler<T> {
    cacheKey: string;
    cacheType: string;
    setter: (data: T, fromCache: boolean) => void;
}

interface IQueueCache {
    data: IQueueInformation | IQueueReport | ITelXLKpiThreshold;
    timeStamp: Date;
}

@Injectable({
    providedIn: 'root',
})
export class QueueService {
    queues = signal<Queue[]>([]);

    constructor(
        private businesUnitService: BusinessUnitService,
        private loggerService: LoggerService,
    ) {}

    set(queueIds: string[]) {
        const queues = queueIds.map(id => new Queue(id));
        this.queues.set(queues);

        this.queues
            .asReadonly()()
            .forEach(queue => {
                const cacheHandlers: CacheHandler<any>[] = [
                    {
                        cacheKey: 'information',
                        cacheType: 'information',
                        setter: data =>
                            this.setInformation(
                                data as IQueueInformation,
                                false,
                            ),
                    },
                    {
                        cacheKey: 'report',
                        cacheType: 'report',
                        setter: data =>
                            this.setReport(data as IQueueReport, false),
                    },
                    {
                        cacheKey: 'threshold',
                        cacheType: 'threshold',
                        setter: data =>
                            this.setKpi(
                                queue.id,
                                undefined,
                                data as ITelXLKpiThreshold,
                                false,
                            ),
                    },
                ];

                cacheHandlers.forEach(handler =>
                    this.handleCache(
                        queue.id,
                        handler.cacheKey,
                        handler.cacheType,
                        handler.setter,
                    ),
                );
            });
    }

    private handleCache<T>(
        queueId: string,
        cacheKey: string,
        cacheType: string,
        setter: (data: T, fromCache: boolean) => void,
    ) {
        const cacheString = sessionStorage.getItem(`${queueId}-${cacheKey}`);
        if (cacheString) {
            const cache = JSON.parse(cacheString) as IQueueCache;
            if (
                differenceInSeconds(new Date(), cache.timeStamp) <
                environment.cacheLifeTime
            ) {
                this.loggerService.debug(
                    `Loading cache for queue ${cacheType} ${queueId}`,
                    cache,
                );

                setter(cache.data as T, false);
            }
        }
    }

    setInformation(queueInformation: IQueueInformation, writeToCache = true) {
        queueInformation.businessUnitId =
            queueInformation.businessUnitId ?? NBU_ID;

        const queueUpdate = this.queues
            .asReadonly()()
            .map(q =>
                q.id === queueInformation.id
                    ? new Queue(
                          q.id,
                          queueInformation,
                          q.report,
                          q.kpiTelXLThreshold,
                      )
                    : q,
            );

        this.queues.set(queueUpdate);
        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueInformation.id) as Queue,
        );

        if (writeToCache) {
            this.saveToCache(
                queueInformation.id,
                'information',
                queueInformation,
            );
        }
    }

    setReport(queueReport: IQueueReport, writeToCache = true) {
        if (queueReport.name === 'Not found') return;

        const queueUpdate = this.queues
            .asReadonly()()
            .map(q =>
                q.id === queueReport.id
                    ? new Queue(
                          q.id,
                          q.information,
                          queueReport,
                          q.kpiTelXLThreshold,
                      )
                    : q,
            );

        this.queues.set(queueUpdate);
        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueReport.id) as Queue,
        );

        if (writeToCache) {
            this.saveToCache(queueReport.id, 'report', queueReport);
        }
    }

    getKpi(queueId: string): ITelXLKpiThreshold {
        return this.queues
            .asReadonly()()
            .find(q => q.id === queueId)!.kpiTelXLThreshold;
    }

    setKpi(
        queueId: string,
        kpiThreshold?: IKpiThreshold,
        telXLKpiThreshold?: ITelXLKpiThreshold,
        writeToCache = true,
    ) {
        const threshold =
            telXLKpiThreshold ??
            this.assignKpiThresholdFromLegacy(kpiThreshold);

        const queueUpdate = this.queues
            .asReadonly()()
            .map(q =>
                q.id === queueId
                    ? new Queue(q.id, q.information, q.report, threshold)
                    : q,
            );

        this.queues.set(queueUpdate);
        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueId) as Queue,
        );

        if (writeToCache && threshold) {
            this.saveToCache(queueId, 'threshold', threshold);
        }
    }

    private saveToCache(queueId: string, cacheKey: string, data: any) {
        const cache: IQueueCache = {
            data,
            timeStamp: new Date(),
        };

        sessionStorage.setItem(`${queueId}-${cacheKey}`, JSON.stringify(cache));
    }

    private assignKpiThresholdFromLegacy(
        legacyKpiThreshold?: IKpiThreshold,
    ): ITelXLKpiThreshold | undefined {
        if (!legacyKpiThreshold) return undefined;

        return {
            slaThreshold: {
                low: legacyKpiThreshold.slaThreshold ?? 0,
                high: legacyKpiThreshold.slaThreshold ?? 0,
                enabled:
                    legacyKpiThreshold.slaThreshold !== undefined &&
                    legacyKpiThreshold.slaThreshold !== null,
            },
            maxConversationsWaiting: {
                low: legacyKpiThreshold.maxConversationsWaiting ?? 0,
                high: legacyKpiThreshold.maxConversationsWaiting ?? 0,
                enabled:
                    legacyKpiThreshold.maxConversationsWaiting !== undefined &&
                    legacyKpiThreshold.maxConversationsWaiting !== null,
            },
            averageWaitTimeSeconds: {
                low: legacyKpiThreshold.averageWaitTimeSeconds ?? 0,
                high: legacyKpiThreshold.averageWaitTimeSeconds ?? 0,
                enabled:
                    legacyKpiThreshold.averageWaitTimeSeconds !== undefined &&
                    legacyKpiThreshold.averageWaitTimeSeconds !== null,
            },
            maxLongestWaitTimeSeconds: {
                low: legacyKpiThreshold.maxLwtSeconds ?? 0,
                high: legacyKpiThreshold.maxLwtSeconds ?? 0,
                enabled:
                    legacyKpiThreshold.maxLwtSeconds !== undefined &&
                    legacyKpiThreshold.maxLwtSeconds !== null,
            },
        };
    }
}
