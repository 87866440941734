import { Injectable, signal } from '@angular/core';
import {
    BusinessUnit,
    IBusinessUnit,
} from '@models/business-unit/business-unit';
import { Queue } from '@models/queue/queue';
import { RbacApiService } from '@services/api/rbac/rbac.api.service';
import { TenantService } from '@services/tenant/tenant.service';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    businessUnits = signal<BusinessUnit[]>([]);

    constructor(
        private rbacApiService: RbacApiService,
        private tenantService: TenantService
    ) {}

    set(businessUnits: IBusinessUnit[]) {
        this.businessUnits.set(
            businessUnits
                .map(bu => new BusinessUnit(bu.id, bu.name, []))
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
        );

        this.businessUnits
            .asReadonly()()
            .forEach(businessUnit =>
                this.rbacApiService
                    .getKpiThresholds(businessUnit.id, 'businessunits')
                    .subscribe({
                        next: kpiThresholds => {
                            if (kpiThresholds) {
                                businessUnit.kpiTelXLThreshold =
                                    kpiThresholds.kpiTelXLThreshold;

                                const businessUnitUpdate = this.businessUnits
                                    .asReadonly()()
                                    .map(bu =>
                                        bu.id === bu.id ? bu : businessUnit
                                    );

                                this.businessUnits.set(businessUnitUpdate);
                            }
                        },
                    })
            );
    }

    updateQueue(queue: Queue) {
        if (!queue.businessUnit || !queue.name || queue.name.length === 0)
            return;

        const businessUnit = this.businessUnits
            .asReadonly()()
            .find(bu => bu.id === queue.businessUnit);

        const queues = [
            ...(businessUnit?.queues.filter(q => q.id !== queue.id) ?? []),
            ...[queue],
        ].sort((a, b) =>
            a.name && b.name
                ? a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                : 0
        );

        const businessUnitUpdate = this.businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === queue.businessUnit
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          queues,
                          bu.kpiTelXLThreshold
                      )
                    : bu
            );

        this.businessUnits.set(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }
}
