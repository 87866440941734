import { Injectable, signal } from '@angular/core';
import { NBU_ID } from '@models/business-unit/business-unit';
import { Queue } from '@models/queue/queue';
import { IQueueInformation, IQueueReport } from '@models/queue/queue';
import { ITelXLKpiThreshold } from '@models/rbac/kpi';
import { RbacApiService } from '@services/api/rbac/rbac.api.service';
import { BusinessUnitService } from '@services/business-unit/business-unit.service';

@Injectable({
    providedIn: 'root',
})
export class QueueService {
    isDirty = signal<boolean>(false);
    queues = signal<Queue[]>([]);

    constructor(
        private rbacApiService: RbacApiService,
        private businesUnitService: BusinessUnitService
    ) {}

    addOrUpdate(queueIds: string[]) {
        const newQueues = queueIds
            .filter(
                id =>
                    !this.queues()
                        .map(q => q.id)
                        .includes(id)
            )
            .map(id => new Queue(id));

        if (newQueues.length > 0) {
            const queueUpdate = [...this.queues.asReadonly()(), ...newQueues];
            this.queues.set(queueUpdate);
            this.isDirty.set(true);

            newQueues.forEach(q =>
                this.rbacApiService.getKpiThresholds(q.id, 'queues').subscribe({
                    next: kpiThresholds => {
                        if (kpiThresholds) {
                            q.kpiThreshold = kpiThresholds.kpiThreshold;
                            q.kpiTelXLThreshold =
                                kpiThresholds.kpiTelXLThreshold;

                            const queueUpdate = this.queues
                                .asReadonly()()
                                .map(queue => (queue.id === q.id ? q : queue));

                            this.queues.set(queueUpdate);
                        }
                    },
                })
            );
        }
    }

    setInformation(queueInformation: IQueueInformation) {
        queueInformation.businessUnitId =
            queueInformation.businessUnitId ?? NBU_ID;

        const queueUpdate = this.queues
            .asReadonly()()
            .map(q =>
                q.id === queueInformation.id
                    ? new Queue(
                          q.id,
                          queueInformation,
                          q.report,
                          q.kpiThreshold,
                          q.kpiTelXLThreshold
                      )
                    : q
            );

        this.queues.set(queueUpdate);
        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueInformation.id) as Queue
        );
    }

    setReport(queueReport: IQueueReport) {
        if (queueReport.name === 'Not found') return;

        const queueUpdate = this.queues
            .asReadonly()()
            .map(q =>
                q.id === queueReport.id
                    ? new Queue(
                          q.id,
                          q.information,
                          queueReport,
                          q.kpiThreshold,
                          q.kpiTelXLThreshold
                      )
                    : q
            );

        this.queues.set(queueUpdate);
        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueReport.id) as Queue
        );
    }

    setKpi(queueId: string, telXLKpiThreshold: ITelXLKpiThreshold) {
        const queueUpdate = this.queues
            .asReadonly()()
            .map(q =>
                q.id === queueId
                    ? new Queue(
                          q.id,
                          q.information,
                          q.report,
                          q.kpiThreshold,
                          telXLKpiThreshold
                      )
                    : q
            );

        this.queues.set(queueUpdate);
        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueId) as Queue
        );
    }
}
