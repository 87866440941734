export enum NotificationType {
    Tier1 = 'Tier1',
    Tier2 = 'Tier2',
    Tier3 = 'Tier3',
}

export enum NotificationActionType {
    Read = 'Read',
    Pinned = 'Pinned',
    Unpinned = 'Unpinned',
    Actioned = 'Actioned',
    Dismissed = 'Dismissed',
}

export interface INotificationModel {
    id: string;
    title: string;
    description: string;
    action: string;
    type: NotificationType;
    created: Date;
    pinned: boolean;
    read: boolean;
    expires?: Date;
}
