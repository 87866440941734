export interface ITimeDifference {
    hours: number;
    minutes: number;
    seconds: number;
}

export const calculateTimeDifference = (
    date1: Date,
    date2: Date
): ITimeDifference => {
    let diff = Math.abs(date2.getTime() - date1.getTime());

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(diff / (1000 * 60));
    diff -= minutes * (1000 * 60);

    const seconds = Math.floor(diff / 1000);

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};

export const secondsToTimeDifference = (
    totalSeconds: number
): ITimeDifference => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 3600) % 60;

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};
