import { computed, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AggregationLevel } from '@models/data/aggregate-level';

export interface Segment {
    id: string;
    label: string;
    url: string;
    level: AggregationLevel;
}

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    private segmentsSubject = new BehaviorSubject<Segment[]>([]);
    segments = toSignal(this.segmentsSubject.asObservable());
    current = computed<Segment | undefined>(() => {
        const segments = this.segments();
        return segments && segments.length > 0
            ? segments[segments.length - 1]
            : undefined;
    });

    constructor(private router: Router) {
        this.updateSegments();

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.updateSegments();
            });
    }

    private updateSegments() {
        const root = this.router.routerState.snapshot.root;
        const segments: Segment[] = [];
        this.addSegment(root, '', segments, AggregationLevel.None);
        this.segmentsSubject.next(segments);
    }

    private addSegment(
        route: ActivatedRouteSnapshot,
        url: string,
        segments: Segment[],
        level: AggregationLevel
    ) {
        if (route) {
            route.url.forEach((segment) => {
                if (segment.path) {
                    level++;
                    url += `/${segment.path}`;
                    segments.push({
                        id: segment.path,
                        label: '',
                        url,
                        level,
                    });
                }
            });

            if (route.children.length > 0) {
                route.children.forEach((child) => {
                    this.addSegment(child, url, segments, level);
                });
            }
        }
    }
}
