import { LogLevel } from './log';

export enum EnvironmentType {
    Local = 'Local',
    Dev = 'Dev',
    QA = 'QA',
    Production = 'Production',
}

export interface IEnvironmentSettings {
    production: boolean;
    logLevel: LogLevel;
    authority: string;
    apiBaseUrl: string;
    blenderUrl: string;
    rbacUrl: string;
    liveUrl: string;
    aggregationsUrl: string;
    environment: EnvironmentType;
    heartbeatInterval: number;
    pollingInterval: number;
    scope: string;
}
