import { IValueWithMetric } from '@models/metrics/metric';
import {
    ITelXLKpiThreshold,
    TelXLKpiThresholdDefaultState,
} from '@models/rbac/kpi';
import { toPercentage } from '@helpers/metric';
import { QueueActivity } from './queue-activity';

export interface IQueue {
    id: string;
    information?: IQueueInformation;
    report?: IQueueReport;
    kpiTelXLThreshold: ITelXLKpiThreshold;
    activity: QueueActivity[];
}

export interface IQueueInformation {
    id: string;
    name: string;
    workflowId: string;
    mediaGroup: string;
    queueSegments: IQueueSegment[];
    agentSegments: IAgentSegment[];
    todaysQueueSegments: IQueueSegment[];
    todaysAgentSegments: IAgentSegment[];
    segments: IQueueSegment[];
    businessUnitId: string;
    staffedLoggedInAgentCount: number;
    staffedAvailableAgentCount: number;
}

export interface IQueueSegment {
    queueId: string;
    queuedAt: Date;
    deQueuedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    position?: number;
    customer: ICustomer;
    channelId: number;
    channelType: string;
    routingMode: 'Manual' | 'Automatic';
    conversationId: string;
    businessUnitId: string;
}

export interface IAgentSegment {
    customer: ICustomer;
    createdAt: Date;
    closedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    queueId: string;
    conversationId: string;
    agents: IAgent[];
}

interface ICustomer {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

interface IAgent {
    id: string;
    name: string;
}

export interface IQueueReport {
    id: string;
    name: string;
    sla: number;
    abandonThreshold: number;
    startOfToday: Date;
    answeredWithinSLARatio: number;
    handledCount: number;
    handledWithinSLACount: number;
    abandonedCount: number;
    abandonedWithinSLACount: number;
    callbackRequestedCount: number;
    callbackAcceptedCount: number;
    longestWaitTimeInSeconds: number;
    avgWaitingTimeInSeconds: number;
    queueTimedOutCount: number;
    conversationCountForAvgWaitingTime: number;
    businessUnitId: string;
}

export interface IQueuePerformace {
    answeredWithinSLAPercentage: IValueWithMetric;
    abandoned: number;
    timedOut: number;
    waiting: IValueWithMetric;
    connected: number;
    success: number;
    complete: number;
    avgWaitingTimeInSeconds: IValueWithMetric;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: IValueWithMetric;
    outOfSla: number;
}

export class Queue implements IQueue {
    id: string;
    information?: IQueueInformation;
    report?: IQueueReport;
    kpiTelXLThreshold: ITelXLKpiThreshold;
    activity: QueueActivity[];

    constructor(
        id: string,
        information?: IQueueInformation,
        report?: IQueueReport,
        kpiTelXLThreshold?: ITelXLKpiThreshold,
    ) {
        this.id = id;
        this.information = information;
        this.report = report;
        this.kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiThresholdDefaultState;

        const agentSegments = [
            ...(this.information?.agentSegments ?? []),
            ...(this.information?.todaysAgentSegments ?? []),
        ];
        const queueSegments = [
            ...(this.information?.queueSegments ?? []),
            ...(this.information?.todaysQueueSegments ?? []),
        ];

        this.activity = queueSegments.map(
            q =>
                new QueueActivity(
                    this.report !== undefined &&
                        new Date(q.queuedAt).getTime() >=
                            new Date(this.report.startOfToday).getTime(),
                    q,
                    agentSegments.find(
                        a => a.conversationId === q.conversationId,
                    ),
                ),
        );
    }

    get name(): string | undefined {
        return this.information?.name;
    }

    get businessUnit(): string | undefined {
        return this.information?.businessUnitId;
    }

    get hasActivity(): boolean {
        try {
            return this.report !== undefined && this.information !== undefined;
        } catch {
            return false;
        }
    }

    get performance(): IQueuePerformace {
        const minimumQueuedAt =
            this.information?.queueSegments &&
            this.information.queueSegments.length > 0
                ? this.information?.queueSegments.reduce((min, current) => {
                      return new Date(current.queuedAt).getTime() <
                          min.getTime()
                          ? new Date(current.queuedAt)
                          : min;
                  }, new Date())
                : new Date();

        return {
            answeredWithinSLAPercentage: {
                value: toPercentage(this.report?.answeredWithinSLARatio),
                metric: {
                    percent: toPercentage(this.report?.answeredWithinSLARatio),
                    states: this.kpiTelXLThreshold.slaThreshold.enabled
                        ? [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.low,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.high,
                                  ),
                                  state: 'success',
                              },
                          ]
                        : [],
                },
            },
            abandoned: this.report?.abandonedCount ?? 0,
            timedOut: this.report?.queueTimedOutCount ?? 0,
            waiting: {
                value: this.information?.queueSegments.length ?? 0,
                metric: this.kpiTelXLThreshold.maxConversationsWaiting.enabled
                    ? {
                          percent: toPercentage(
                              (this.information?.queueSegments.length ?? 0) /
                                  this.kpiTelXLThreshold.maxConversationsWaiting
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxConversationsWaiting.low /
                                          this.kpiTelXLThreshold
                                              .maxConversationsWaiting.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            connected: this.information?.agentSegments.length ?? 0,
            success: this.report?.handledWithinSLACount ?? 0,
            complete: this.report?.handledCount ?? 0,
            avgWaitingTimeInSeconds: {
                value: this.report?.avgWaitingTimeInSeconds ?? 0,
                metric: this.kpiTelXLThreshold.averageWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              (this.report?.avgWaitingTimeInSeconds ?? 0) /
                                  this.kpiTelXLThreshold.averageWaitTimeSeconds
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .averageWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .averageWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            currentLongestWaitingTimeStamp:
                this.information?.queueSegments &&
                this.information?.queueSegments?.length > 0
                    ? minimumQueuedAt
                    : undefined,
            longestWaitingTimeInSeconds: {
                value: this.report?.longestWaitTimeInSeconds ?? 0,
                metric: this.kpiTelXLThreshold.maxLongestWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              (this.report?.longestWaitTimeInSeconds ?? 0) /
                                  this.kpiTelXLThreshold
                                      .maxLongestWaitTimeSeconds.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            outOfSla:
                (this.report?.handledCount ?? 0) -
                (this.report?.handledWithinSLACount ?? 0),
        };
    }
}
