<div class="card px-0 ms-2 task-item  {{ slideClass }}" (click)="onClick()" 
    [ngClass]="isSelected ? selectedClass : ''">
    <div class="card-body py-0 px-0">
        <div class="d-flex">
            <div class="ms-2 me-2">
                <i [ngClass]="isSelected ? iconSelected : icon"></i>
            </div>
            <div class="ms-1">
                <div class="task-name">
                    {{taskName}}
                </div>
                <div class="task-description">
                    {{description}}
                </div>
                <div class="task-time mt-1">
                    {{createdAt}}
                </div>
            </div>
            <div></div>
        </div>  
    </div>
</div>