import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SpinnerInputComponent } from '@components/shared/spinner-input/spinner-input.component';

export enum RagPanelType {
    Percentage = 'Percentage',
    Time = 'Time',
    Numeric = 'Numeric',
}
export interface KpiValueChange {
    lowValue: number | null;
    highValue: number | null;
    isValid: boolean;
}

@Component({
    selector: 'rag-panel',
    standalone: true,
    imports: [CommonModule, FormsModule, SpinnerInputComponent],
    templateUrl: './rag-panel.component.html',
    styleUrl: './rag-panel.component.scss',
})
export class RagPanelComponent implements OnChanges {
    RagPanelType = RagPanelType;
    @Input({ required: true }) title!: string;
    @Input({ required: true }) ragPanelType!: RagPanelType;
    @Input({ required: true }) lowValue!: number | null;
    @Input({ required: true }) highValue!: number | null;
    @Input() min!: number;
    @Input() max!: number;
    @Output() valueChange: EventEmitter<KpiValueChange> =
        new EventEmitter<KpiValueChange>();

    isValid = true;
    isEnabled!: boolean;
    internalLowValue = this.lowValue;
    internalHighValue = this.highValue;

    internalLowTimeHValue = this.lowValueHours;
    internalLowTimeMValue = this.lowValueMinutes;
    internalLowTimeSValue = this.lowValueSeconds;

    internalHighTimeMValue = this.highValueMinutes;
    internalHighTimeHValue = this.highValueHours;
    internalHighTimeSValue = this.highValueSeconds;

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes['lowValue'] &&
            changes['lowValue'].currentValue !== undefined &&
            changes['lowValue'].currentValue !== null
        ) {
            this.lowValue =
                this.ragPanelType === RagPanelType.Numeric
                    ? changes['lowValue'].currentValue
                    : Math.round(changes['lowValue'].currentValue * 100);
            this.internalLowValue = changes['lowValue'].currentValue;
            this.internalLowTimeHValue = this.lowValueHours;
            this.internalLowTimeMValue = this.lowValueMinutes;
            this.internalLowTimeSValue = this.lowValueSeconds;
            this.isEnabled = this.lowValue !== null || this.highValue !== null;
        }

        if (
            changes['highValue'] &&
            changes['highValue'].currentValue !== undefined &&
            changes['highValue'].currentValue !== null
        ) {
            this.highValue =
                this.ragPanelType === RagPanelType.Numeric
                    ? changes['highValue'].currentValue
                    : Math.round(changes['highValue'].currentValue * 100);
            this.internalHighValue = changes['highValue'].currentValue;
            this.internalHighTimeHValue = this.highValueHours;
            this.internalHighTimeMValue = this.highValueMinutes;
            this.internalHighTimeSValue = this.highValueSeconds;
            this.isEnabled = this.lowValue !== null || this.highValue !== null;
        }
    }

    onLowValueChange(newValue: number | null) {
        this.internalLowValue =
            this.ragPanelType === RagPanelType.Numeric
                ? newValue ?? 0
                : (newValue ?? 0) / 100;

        this.emitValueChange();
    }

    onHighValueChange(newValue: number | null) {
        this.internalHighValue =
            this.ragPanelType === RagPanelType.Numeric
                ? newValue ?? 0
                : (newValue ?? 0) / 100;

        this.emitValueChange();
    }

    onLowValueTimeChange(value: { type: string; newValue: number | null }) {
        switch (value.type) {
            case 'H':
                this.internalLowTimeHValue = value.newValue;
                break;

            case 'M':
                this.internalLowTimeMValue = value.newValue;
                break;

            case 'S':
                this.internalLowTimeSValue = value.newValue;
                break;
        }

        this.internalLowValue =
            this.internalLowTimeHValue !== null &&
            this.internalLowTimeMValue !== null &&
            this.internalLowTimeSValue !== null
                ? this.internalLowTimeHValue * 3600 +
                  this.internalLowTimeMValue * 60 +
                  this.internalLowTimeSValue
                : null;

        this.emitValueChange();
    }

    onHighValueTimeChange(value: { type: string; newValue: number | null }) {
        switch (value.type) {
            case 'H':
                this.internalHighTimeHValue = value.newValue;
                break;

            case 'M':
                this.internalHighTimeMValue = value.newValue;
                break;

            case 'S':
                this.internalHighTimeSValue = value.newValue;
                break;
        }

        this.internalHighValue =
            this.internalHighTimeHValue !== null &&
            this.internalHighTimeMValue !== null &&
            this.internalHighTimeSValue !== null
                ? this.internalHighTimeHValue * 3600 +
                  this.internalHighTimeMValue * 60 +
                  this.internalHighTimeSValue
                : null;

        this.emitValueChange();
    }

    get lowValueHours(): number | null {
        return this.internalLowValue
            ? Math.floor(this.internalLowValue / 3600)
            : null;
    }

    get lowValueMinutes(): number | null {
        return this.internalLowValue
            ? Math.floor((this.internalLowValue % 3600) / 60)
            : null;
    }

    get lowValueSeconds(): number | null {
        return this.internalLowValue
            ? Math.floor((this.internalLowValue % 3600) % 60)
            : null;
    }

    get highValueHours(): number | null {
        return this.internalHighValue
            ? Math.floor(this.internalHighValue / 3600)
            : null;
    }

    get highValueMinutes(): number | null {
        return this.internalHighValue
            ? Math.floor((this.internalHighValue % 3600) / 60)
            : null;
    }

    get highValueSeconds(): number | null {
        return this.internalHighValue
            ? Math.floor((this.internalHighValue % 3600) % 60)
            : null;
    }

    private emitValueChange() {
        (this.isValid = !(
            this.internalLowValue === null ||
            this.internalHighValue === null ||
            this.internalLowValue > this.internalHighValue
        )),
            this.valueChange.emit({
                lowValue: this.internalLowValue,
                highValue: this.internalHighValue,
                isValid: this.isValid,
            });
    }
}
