import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { AggregationLevel } from '@models/data/aggregate-level';
import { TitlePanelComponent } from '../title-panel/title-panel.component';
import { QueueHeaderZoneComponent } from './queue-header-zone/queue-header-zone.component';
import { RagStatusComponent } from './rag-status/rag-status.component';
import { NavigationStart, Router } from '@angular/router';
import { BusinessUnitHeaderZoneComponent } from './business-unit-header-zone/business-unit-header-zone.component';
import { TenantHeaderZoneComponent } from './tenant-header-zone/tenant-header-zone.component';

@Component({
    selector: 'header-zone',
    standalone: true,
    imports: [
        CommonModule,
        TitlePanelComponent,
        QueueHeaderZoneComponent,
        BusinessUnitHeaderZoneComponent,
        TenantHeaderZoneComponent,
        RagStatusComponent,
    ],
    templateUrl: './header-zone.component.html',
    styleUrl: './header-zone.component.scss',
})
export class HeaderZoneComponent implements OnInit {
    AggregationLevel = AggregationLevel;
    current = this.breadcrumbService.current;
    isRAGStatusPanelVisible = false;
    isExpanded = false;
    isDirty = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isRAGStatusPanelVisible = false;
            }
        });
    }

    ngOnInit() {
        const expandedState = sessionStorage.getItem('isHeaderZoneExpanded');
        this.isExpanded = expandedState === 'true';
    }

    onCloseRAGPanel() {
        this.isRAGStatusPanelVisible = false;
        this.isDirty = false;
    }

    toggleExpandedView() {
        this.isExpanded = !this.isExpanded;
        sessionStorage.setItem(
            'isHeaderZoneExpanded',
            this.isExpanded.toString()
        );
    }
}
