import '@angular/localize/init';
import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationHubService } from '@services/signalr/blenderGara/notification/notification.hub.service';
import {
    INotificationModel,
    NotificationActionType,
    NotificationType,
} from '@models/notification/notification';

@Component({
    selector: 'notification-toasts',
    imports: [CommonModule, NgbToastModule],
    standalone: true,
    templateUrl: './notification-toast.component.html',
    styleUrls: ['./notification-toast.component.scss'],
})
export class NotificationToastComponent {
    private notifications = this.notificationHubService.notifications;
    tier2 = computed<INotificationModel[]>(() =>
        this.notifications().filter((n) => n.type === NotificationType.Tier2)
    );

    constructor(private notificationHubService: NotificationHubService) {}

    dismissNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Dismissed
        );
    }

    actionNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Actioned
        );
    }
}
