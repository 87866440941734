import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, share, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { LoggerService } from '@services/logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class PresenceApiService {
    breakNames = signal<string[]>([]);

    constructor(private logger: LoggerService, private http: HttpClient) {}

    login(): Observable<void> {
        return this.http
            .post<void>(environment.blenderUrl + '/resource/login', null)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Presence Service (Login) -> Successful'
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Presence Service (Login) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    logout(): Observable<void> {
        return this.http
            .post<void>(environment.blenderUrl + '/resource/logout', null)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Presence Service (Logout) -> Successful'
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Presence Service (Logout) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    activate() {
        this.http
            .post<void>(environment.blenderUrl + '/resource/activate', null)
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Presence Service (Agent Activation) -> Successful'
                    );
                },
                error: error => {
                    this.logger.error(
                        'Presence Service (Agent Activation) -> Failed',
                        error
                    );
                },
            });
    }

    goToBreak(breakName: string) {
        this.http
            .post<void>(
                environment.blenderUrl +
                    `/resource/moveToBreak?breakName=${breakName ?? ''}`,
                null
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Presence Service (Agent on Break) -> Successful'
                    );
                },
                error: error => {
                    this.logger.error(
                        'Presence Service (Agent on Break) -> Failed',
                        error
                    );
                },
            });
    }

    getBreakNames() {
        this.http
            .get<string[]>(environment.blenderUrl + '/resource/breaknames')
            .subscribe({
                next: breakNames => {
                    this.breakNames.set(breakNames);

                    this.logger.debug(
                        'Presence Service (Get Break Names) -> Successful',
                        breakNames
                    );
                },
                error: error => {
                    this.logger.error(
                        'Presence Service (Get Break Names) -> Failed',
                        error
                    );
                },
            });
    }
}
