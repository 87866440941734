import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials',
    standalone: true,
})
export class InitialsPipe implements PipeTransform {
    transform(fullName: string): string {
        if (!fullName) {
            return '';
        }

        const names = fullName.trim().split(/\s+/);
        let initials = names[0].charAt(0).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].charAt(0).toUpperCase();
        }

        return initials;
    }
}
