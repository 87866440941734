export interface INavBarDataModel {
    routeLink: string;
    icon: string;
    label: string;
}

export const navbarData: INavBarDataModel[] = [
    {
        routeLink: 'dashboard',
        icon: 'fa-house',
        label: 'Home',
    },
    {
        routeLink: 'queues',
        icon: 'fa-list',
        label: 'Queues',
    },
    {
        routeLink: 'contacts',
        icon: 'fa-users',
        label: 'Contacts',
    },
    {
        routeLink: 'performance',
        icon: 'fa-chart-simple',
        label: 'Performance',
    },
    {
        routeLink: 'Voice',
        icon: 'fa-phone',
        label: 'Voice',
    },
    {
        routeLink: 'WebChat',
        icon: 'fa-message',
        label: 'WebChat',
    },
    {
        routeLink: 'Email',
        icon: 'fa-envelope',
        label: 'Email',
    },
    {
        routeLink: 'WhatsApp',
        icon: 'fa-brands fa-whatsapp',
        label: 'WhatsApp',
    },
    {
        routeLink: 'Social',
        icon: 'fa-share-nodes',
        label: 'Social',
    },
    {
        routeLink: 'debug',
        icon: 'fa-debug',
        label: 'Debug',
    },
];

export const collapsedNavbarData: INavBarDataModel[] = [
    {
        routeLink: 'dashboard',
        icon: 'fa-house',
        label: 'Home',
    },
    {
        routeLink: 'queues',
        icon: 'fa-list',
        label: 'Queues',
    },
    {
        routeLink: 'contacts',
        icon: 'fa-users',
        label: 'Contacts',
    },
    {
        routeLink: 'performance',
        icon: 'fa-chart-simple',
        label: 'Performance',
    },
    {
        routeLink: 'Voice',
        icon: 'fa-phone',
        label: 'Voice',
    },
    {
        routeLink: 'WebChat',
        icon: 'fa-message',
        label: 'WebChat',
    },
    {
        routeLink: 'Email',
        icon: 'fa-envelope',
        label: 'Email',
    },
    {
        routeLink: 'WhatsApp',
        icon: 'fa-brands fa-whatsapp',
        label: 'WhatsApp',
    },
    {
        routeLink: 'Social',
        icon: 'fa-share-nodes',
        label: 'Social',
    },
    {
        routeLink: 'debug',
        icon: 'fa-debug',
        label: 'Debug',
    },
];
