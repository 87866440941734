<nav class="navbar navbar-expand-lg ps-1 pe-1 pt-1 pb-1">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img
                class="brand-image"
                src="assets/images/cxp-signet.svg"
                alt="Cxp Logo"
                id="header-brand-image"
            />
        </a>

        <div class="d-flex ms-auto align-items-center">
            <div class="me-2 pe-4" id="header-current-date">
                {{ currentDate | date : 'HH:mm | EEEE dd/MM/YYYY' }}
            </div>
            <div class="w-60 me-2 text-center" id="header-question">
                <i class="fa-light fa-lg fa-circle-question"></i>
            </div>
            <div
                class="w-60 me-2 text-center"
                id="header-notification"
                (click)="toggleNotificationPanel()"
            >
                <i class="fa-light fa-lg fa-bell position-relative">
                    @if(unread() > 0) {
                    <span
                        class="position-absolute top-0 start-100 badge rounded-pill bg-danger"
                    >
                        {{ unread() }}
                        <span class="visually-hidden">unread messages</span>
                    </span>
                    }
                </i>
            </div>

            <div
                ngbDropdown
                class="d-inline-block"
                [ngClass]="{
                    'header-user-status-menu-top': isUserDropdownOpen
                }"
                (openChange)="toggleDropdownOpenState($event)"
                [autoClose]="false"
                id="header-user-control"
            >
                <div
                    class="avatar d-flex align-items-center justify-content-{{
                        isUserDropdownOpen ? 'between' : 'end'
                    }}"
                >
                    @if(isUserDropdownOpen) {
                    <div
                        class="ms-3 form-check form-switch"
                        id="header-user-presence-switch"
                    >
                        <input
                            class="form-check-input bg-{{
                                presence().class
                            }} border-{{ presence().class }}"
                            type="checkbox"
                            role="switch"
                            [checked]="presence().online"
                            (click)="changePresence()"
                        />
                    </div>
                    }

                    <div
                        class="position-relative px-1"
                        ngbDropdownToggle
                        role="button"
                        id="header-user-menu-toggle"
                    >
                        <div
                            class="rounded-circle user-initials"
                            id="header-user-initials"
                        >
                            {{ user().name | initials }}
                        </div>
                        <!--<img src="assets/images/avatar.png" class="rounded-circle" alt="{{username}}" id="header-user-avatar" style="width: 60px; height: 60px;">-->
                        <span
                            class="position-absolute top-0 start-100 translate-middle p-2 border border-white border-2 rounded-circle status-badge bg-{{
                                presence().class
                            }}"
                        ></span>
                    </div>
                </div>

                <div
                    ngbDropdownMenu
                    class="dropdown-menu-end"
                    id="header-user-status-menu"
                >
                    <div class="d-flex flex-column align-items-start ms-3 me-3">
                        <span
                            class="font-large weight-medium mb-2"
                            id="header-user-name"
                        >
                            {{ user().name }}
                        </span>

                        <div class="mb-2">
                            <span class="font-small me-2">Status:</span>
                            <span
                                class="badge font-small weight-light border border-{{
                                    presence().class
                                }} text-bg-{{ presence().class }}"
                                id="header-user-presence"
                            >
                                {{ presence().label }}
                            </span>
                        </div>
                        <span
                            class="font-large weight-medium mb-2"
                            id="header-user-time"
                        >
                            {{ stateSince | formatDuration }}
                        </span>

                        @if(presence().online) {
                        <div
                            ngbDropdown
                            class="d-inline-block w-100"
                            id="header-user-presence-menu"
                            (openChange)="toggleBreakDropdownOpenState($event)"
                        >
                            <button
                                type="button"
                                class="btn btn-secondary d-flex justify-content-between align-items-center w-100"
                                ngbDropdownToggle
                                id="header-user-set-presence-type"
                            >
                                Select break type
                                <i
                                    class="fas"
                                    [ngClass]="
                                        isBreakDropdownOpen
                                            ? 'fa-chevron-up'
                                            : 'fa-chevron-down'
                                    "
                                ></i>
                            </button>
                            <div
                                ngbDropdownMenu
                                class=""
                                id="header-user-set-presence-type-menu"
                            >
                                @for(breakName of breakNames(); track breakName)
                                {
                                <button
                                    ngbDropdownItem
                                    (click)="goToBreak(breakName)"
                                    class="w-100"
                                    id="header-user-set-presence-type-{{
                                        breakName | lowercase | replaceSpaces
                                    }}"
                                >
                                    {{ breakName }}
                                </button>
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
