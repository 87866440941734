import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'silent-renew',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './silent-renew.component.html',
})
export class SilentRenewComponent {}
