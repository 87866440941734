import { Injectable, signal } from '@angular/core';
import { RbacApiService } from '@api/rbac/rbac.api.service';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { ITelXLKpiThreshold } from '@models/rbac/kpi';
import { IRbacTenant } from '@models/rbac/tenant';
import { Tenant } from '@models/tenant/tenant';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    tenant = signal<Tenant>({} as Tenant);

    constructor(private rbacApiService: RbacApiService) {}

    set(tenant: IRbacTenant) {
        this.tenant.set(new Tenant(tenant.id, tenant.name, []));

        this.rbacApiService.getKpiThresholds(tenant.id, 'tenants').subscribe({
            next: kpiThresholds => {
                if (kpiThresholds) {
                    this.tenant.set(
                        new Tenant(
                            tenant.id,
                            tenant.name,
                            [],
                            kpiThresholds.kpiTelXLThreshold,
                        ),
                    );
                }
            },
        });
    }

    getKpi(): ITelXLKpiThreshold {
        return this.tenant.asReadonly()().kpiTelXLThreshold;
    }

    setKpi(telXLKpiThreshold: ITelXLKpiThreshold) {
        const tenent = this.tenant.asReadonly()();

        this.tenant.set(
            new Tenant(
                tenent.id,
                tenent.name,
                tenent.businessUnits,
                telXLKpiThreshold,
            ),
        );
    }

    updateBusinessUnits(businessUnits: BusinessUnit[]) {
        const tenent = this.tenant.asReadonly()();

        this.tenant.set(
            new Tenant(
                tenent.id,
                tenent.name,
                businessUnits,
                tenent.kpiTelXLThreshold,
            ),
        );
    }
}
