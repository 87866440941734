<div
    style="display: flex; justify-content: space-evenly; align-items: center"
    [style.height]="height"
    [style.width]="width"
>
    <div class="-m-5">
        <div class="font-small weight-medium p-0 m-0">{{ label }}</div>
        <div class="font-largest weight-medium p-0 m-0">
            {{ overall }}
            <span class="font-small weight-heavy">@if(percentage){%}</span>
        </div>
    </div>
    <canvas
        baseChart
        [data]="doughnutChartData"
        [options]="doughnutChartOptions"
        [type]="doughnutChartType"
    ></canvas>
</div>
