import { startOfToday } from 'date-fns';
import { IMetric } from '@models/metrics/metric';
import { IKpiThreshold, ITelXLKpiThreshold } from '@models/rbac/kpi';
import { QueueActivity } from './queue-activity';

export interface IQueue {
    id: string;
    information?: IQueueInformation;
    report?: IQueueReport;
    kpiThreshold?: IKpiThreshold;
    kpiTelXLThreshold?: ITelXLKpiThreshold;
    activity: QueueActivity[];
}

export interface IQueueInformation {
    id: string;
    name: string;
    workflowId: string;
    mediaGroup: string;
    queueSegments: IQueueSegment[];
    agentSegments: IAgentSegment[];
    todaysQueueSegments: IQueueSegment[];
    todaysAgentSegments: IAgentSegment[];
    segments: IQueueSegment[];
    businessUnitId: string;
    staffedLoggedInAgentCount: number;
    staffedAvailableAgentCount: number;
}

export interface IQueueSegment {
    queueId: string;
    queuedAt: Date;
    deQueuedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    position?: number;
    customer: ICustomer;
    channelId: number;
    channelType: string;
    routingMode: RoutingMode;
    conversationId: string;
    businessUnitId: string;
}

export interface IAgentSegment {
    customer: ICustomer;
    createdAt: Date;
    closedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    queueId: string;
    conversationId: string;
    agents: IAgent[];
}

export interface ICustomer {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

export interface IAgent {
    id: string;
    name: string;
}

export enum RoutingMode {
    Automatic = 'Automatic',
    Manual = 'Manual',
}

export interface IQueueReport {
    id: string;
    name: string;
    sla: number;
    abandonThreshold: number;
    startOfToday: Date;
    answeredWithinSLARatio: number;
    handledCount: number;
    handledWithinSLACount: number;
    abandonedCount: number;
    abandonedWithinSLACount: number;
    callbackRequestedCount: number;
    callbackAcceptedCount: number;
    longestWaitTimeInSeconds: number;
    avgWaitingTimeInSeconds: number;
    queueTimedOutCount: number;
    conversationCountForAvgWaitingTime: number;
    businessUnitId: string;
}

export interface IQueuePerformace {
    answeredWithinSLAPercentage: number;
    answeredWithinSLAPercentageMetric: IMetric;
    abandoned: number;
    timedOut: number;
    waiting: number;
    waitingMetric: IMetric;
    connected: number;
    success: number;
    complete: number;
    avgWaitingTimeInSeconds: number;
    avgWaitingTimeInSecondsMetric: IMetric;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: number;
    longestWaitingTimeInSecondsMetric: IMetric;
    outOfSla: number;
}

export class Queue implements IQueue {
    id: string;
    information?: IQueueInformation;
    report?: IQueueReport;
    kpiThreshold?: IKpiThreshold;
    kpiTelXLThreshold?: ITelXLKpiThreshold;
    activity: QueueActivity[];

    constructor(
        id: string,
        information?: IQueueInformation,
        report?: IQueueReport,
        kpiThreshold?: IKpiThreshold,
        kpiTelXLThreshold?: ITelXLKpiThreshold
    ) {
        this.id = id;
        this.information = information;
        this.report = report;
        this.kpiThreshold = kpiThreshold;
        this.kpiTelXLThreshold = kpiTelXLThreshold ?? this.assignFromLegacy;

        const agentSegments = [
            ...(this.information?.agentSegments ?? []),
            ...(this.information?.todaysAgentSegments ?? []),
        ];
        const queueSegments = [
            ...(this.information?.queueSegments ?? []),
            ...(this.information?.todaysQueueSegments ?? []),
        ];

        this.activity = queueSegments
            .filter(
                q =>
                    new Date(q.queuedAt).getTime() >=
                    (this.report?.startOfToday
                        ? new Date(this.report.startOfToday).getTime()
                        : startOfToday().getTime())
            )
            .map(
                q =>
                    new QueueActivity(
                        q,
                        agentSegments.find(
                            a => a.conversationId === q.conversationId
                        )
                    )
            );
    }

    private get assignFromLegacy(): ITelXLKpiThreshold {
        return {
            slaThresholdLow: this.kpiThreshold?.slaThreshold,
            slaThresholdHigh: this.kpiThreshold?.slaThreshold,
            maxConversationsLowWaiting:
                this.kpiThreshold?.maxConversationsWaiting,
            maxConversationsHighWaiting:
                this.kpiThreshold?.maxConversationsWaiting,
            averageWaitTimeLowSeconds:
                this.kpiThreshold?.averageWaitTimeSeconds,
            averageWaitTimeHighSeconds:
                this.kpiThreshold?.averageWaitTimeSeconds,
            maxLongestWaitTimeLowSeconds: this.kpiThreshold?.maxLwtSeconds,
            maxLongestWaitTimeHighSeconds: this.kpiThreshold?.maxLwtSeconds,
        };
    }

    get name(): string | undefined {
        return this.information?.name;
    }

    get businessUnit(): string | undefined {
        return this.information?.businessUnitId;
    }

    get isConfigured(): boolean {
        const result =
            this.kpiTelXLThreshold !== undefined &&
            Object.values(this.kpiTelXLThreshold).some(
                value => value !== undefined
            );

        return result;
    }

    get performance(): IQueuePerformace {
        const minimumQueuedAt =
            this.information?.queueSegments &&
            this.information.queueSegments.length > 0
                ? this.information?.queueSegments.reduce((min, current) => {
                      return new Date(current.queuedAt).getTime() <
                          min.getTime()
                          ? new Date(current.queuedAt)
                          : min;
                  }, new Date())
                : new Date();

        return {
            answeredWithinSLAPercentage: Math.round(
                (this.report?.answeredWithinSLARatio ?? 0) * 100
            ),
            answeredWithinSLAPercentageMetric: {
                percent: Math.round(
                    (this.report?.answeredWithinSLARatio ?? 0) * 100
                ),
                state: [
                    Math.round(
                        (this.kpiTelXLThreshold?.slaThresholdLow ?? 1) * 100
                    ),
                    Math.round(
                        (this.kpiTelXLThreshold?.slaThresholdHigh ?? 1) * 100
                    ),
                ],
            },
            abandoned: this.report?.abandonedCount ?? 0,
            timedOut: this.report?.queueTimedOutCount ?? 0,
            waiting: this.information?.queueSegments.length ?? 0,
            waitingMetric: this.kpiTelXLThreshold?.maxConversationsHighWaiting
                ? {
                      percent: Math.round(
                          ((this.information?.queueSegments.length ?? 0) /
                              (this.kpiTelXLThreshold
                                  ?.maxConversationsHighWaiting ?? 1)) *
                              100
                      ),

                      state: [
                          Math.round(
                              ((this.kpiTelXLThreshold
                                  ?.maxConversationsLowWaiting ?? 1) /
                                  (this.kpiTelXLThreshold
                                      ?.maxConversationsHighWaiting ?? 1)) *
                                  100
                          ),
                          100,
                      ],
                  }
                : { percent: 0, state: [100, 100] },
            connected: this.information?.agentSegments.length ?? 0,
            success: this.report?.handledWithinSLACount ?? 0,
            complete: this.report?.handledCount ?? 0,
            avgWaitingTimeInSeconds: this.report?.avgWaitingTimeInSeconds ?? 0,
            avgWaitingTimeInSecondsMetric: {
                percent: Math.round(
                    ((this.report?.avgWaitingTimeInSeconds ?? 0) /
                        (this.kpiTelXLThreshold?.averageWaitTimeHighSeconds ??
                            1)) *
                        100
                ),
                state: [
                    Math.round(
                        ((this.kpiTelXLThreshold?.averageWaitTimeLowSeconds ??
                            1) /
                            (this.kpiTelXLThreshold
                                ?.averageWaitTimeHighSeconds ?? 1)) *
                            100
                    ),
                    100,
                ],
            },
            currentLongestWaitingTimeStamp:
                this.information?.queueSegments &&
                this.information?.queueSegments?.length > 0
                    ? minimumQueuedAt
                    : undefined,
            longestWaitingTimeInSeconds:
                this.report?.longestWaitTimeInSeconds ?? 0,
            longestWaitingTimeInSecondsMetric: {
                percent: Math.round(
                    ((this.report?.longestWaitTimeInSeconds ?? 0) /
                        (this.kpiTelXLThreshold
                            ?.maxLongestWaitTimeHighSeconds ?? 1)) *
                        100
                ),
                state: [
                    Math.round(
                        ((this.kpiTelXLThreshold
                            ?.maxLongestWaitTimeLowSeconds ?? 1) /
                            (this.kpiTelXLThreshold
                                ?.maxLongestWaitTimeHighSeconds ?? 1)) *
                            100
                    ),
                    100,
                ],
            },
            outOfSla:
                (this.report?.handledCount ?? 0) -
                (this.report?.handledWithinSLACount ?? 0),
        };
    }
}
