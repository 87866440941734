<div class="p-2 text-black" id="notification-panel">
    <div class="offcanvas-header pb-2">
        <i class="fa-solid fa-lg fa-bell me-4"></i>
        <h5 class="offcanvas-title bold">Notifications</h5>
        <button
            type="button"
            class="btn-close text-reset btn-tiny"
            aria-label="Close"
            (click)="activeOffcanvas.dismiss('Cross click')"
        ></button>
    </div>
    <div class="offcanvas-body pt-1">
        @if(tier3().length === 0) {
            <div class="text-center">No notifications</div>
        }
        @for(notification of tier3(); track notification.id) {
            <div class="card mb-3" id=`notification-panel-card-${notification.id}`>
                <div class="card-body ps-3 pe-2 pt-1 pb-1">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="card-title mb-0">{{ notification.title }}</h5>
                        <button
                            type="button"
                            class="btn-close text-reset"
                            aria-label="Close"
                            (click)="dismissNotification(notification)"
                        ></button>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 class="card-subtitle mb-1 text-muted">{{ notification.description }}</h6>
                            <p class="card-text">{{ notification.created | date : "HH:mm - dd/MM/YYYY" }}</p>
                        </div>
                        <div class="nowrap">
                            <i class="fa-sm fa-thumbtack pin me-2 pointer" [ngClass]="notification.pinned ? 'fa-solid' : 'fa-light'" (click)="toggleNotificationPinned(notification)"></i>
                            <button class="btn btn-sm btn-outline-secondary action ps-3 pe-3 pt-1 pb-1" (click)="actionNotification(notification)">Action</button>
                        </div>
                    </div>  
                </div>
            </div>
        }
    </div>
</div>