import { Injectable, computed, signal } from '@angular/core';
import { take } from 'rxjs';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggerService } from '@services/logger/logger.service';
import { environment } from '@core/environments/environment';
import { LogLevel as AxisLogLevel } from '@models/configuration/log';

function logLevel() {
    switch (environment.logLevel) {
        case AxisLogLevel.Debug:
            return LogLevel.Debug;
        case AxisLogLevel.Warning:
            return LogLevel.Warning;
        case AxisLogLevel.Information:
            return LogLevel.Information;
        case AxisLogLevel.Error:
            return LogLevel.Error;
        default:
            return LogLevel.None;
    }
}

export interface ISignalRStatistics {
    state: HubConnectionState;
    lastMessage?: Date;
    count: number;
}

@Injectable({
    providedIn: 'root',
})
export abstract class SignalRHubService {
    protected hubConnection!: HubConnection;

    private lastMessageSignal = signal<Date | undefined>(undefined);
    private messageCountSignal = signal<number>(0);
    private connectionStateSignal = signal<HubConnectionState>(
        HubConnectionState.Disconnected,
    );

    statistics = computed<ISignalRStatistics>(() => {
        return {
            state: this.connectionStateSignal(),
            lastMessage: this.lastMessageSignal(),
            count: this.messageCountSignal(),
        };
    });

    constructor(
        private name: string,
        private url: string,
        private oidcSecurityService: OidcSecurityService,
        protected logger: LoggerService,
    ) {}

    start(parameters?: Record<string, string[]>) {
        this.oidcSecurityService
            .getAccessToken()
            .pipe(take(1))
            .subscribe(token => {
                this.hubConnection = new HubConnectionBuilder()
                    .withUrl(`${this.url}${this.parseParameters(parameters)}`, {
                        accessTokenFactory: () => token,
                    })
                    .configureLogging(logLevel())
                    .withAutomaticReconnect()
                    .build();

                this.hubConnection.onreconnected(() => {
                    this.logger.info(
                        `${this.name} Service -> Reconnected successfully`,
                    );

                    this.updateConnectionState(HubConnectionState.Connected);
                });

                this.hubConnection.onclose(() => {
                    this.logger.info(
                        `${this.name} Service -> Connection closed`,
                    );

                    this.updateConnectionState(HubConnectionState.Disconnected);
                });

                this.hubConnection
                    .start()
                    .then(() => {
                        this.updateConnectionState(
                            HubConnectionState.Connected,
                        );
                        this.onStart();

                        this.logger.info(
                            `${this.name} Service -> Connection started`,
                        );
                    })
                    .catch(error =>
                        this.logger.error(
                            `${this.name} Service -> Error while starting connection`,
                            error,
                        ),
                    );

                this.registerHandlers();
            });
    }

    stop(): Promise<void> {
        if (this.hubConnection) {
            this.unregisterHandlers();

            return this.hubConnection
                .stop()
                .then(() => {
                    this.updateConnectionState(HubConnectionState.Disconnected);
                    this.onStop();

                    this.logger.info(
                        `${this.name} Service -> Connection stopped`,
                    );
                })
                .catch(error =>
                    this.logger.error(
                        `${this.name} Service -> Error while stopping connection`,
                        error,
                    ),
                );
        }

        return Promise.resolve();
    }

    restart(parameters?: Record<string, string[]>) {
        this.stop()
            .then(() => {
                this.logger.info(
                    `${this.name} Service -> Restarting connection`,
                );
                this.start(parameters);
            })
            .catch(error => {
                this.logger.error(
                    `${this.name} Service -> Error while restarting connection`,
                    error,
                );
            });
    }

    get state(): HubConnectionState {
        return this.hubConnection
            ? this.hubConnection.state
            : HubConnectionState.Disconnected;
    }

    protected messageReceived() {
        this.lastMessageSignal.set(new Date());
        this.messageCountSignal.update(count => count + 1);
    }

    private parseParameters(parameters?: Record<string, string[]>): string {
        if (parameters) {
            return `?${Object.keys(parameters)
                .map(key => `${key}=${parameters[key].join(',')}`)
                .join('&')}`;
        }

        return '';
    }

    private updateConnectionState(state: HubConnectionState) {
        this.connectionStateSignal.set(state);
    }

    protected onStart() {}
    protected onStop() {}
    protected registerHandlers() {}
    protected unregisterHandlers() {}
}
