import { Injectable, signal } from '@angular/core';
import { IUser } from '@models/auth/user';
import { IRbacRole } from '@models/rbac/permissions';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private permissions: Map<string, Set<string>> = new Map();

    default: IUser = {
        id: '',
        name: '',
        emailAddress: '',
        authenticated: false,
        enabled: false,
        phoneNumber: '',
        profilePictureUrl: null,
        photo: undefined,
        tenantId: '',
        displayName: '',
        jobTitle: '',
        tenants: [],
        tenant: undefined,
    };

    user = signal<IUser>(this.default);

    update(user: IUser) {
        this.user.set({ ...this.user(), ...user });
    }

    clear() {
        this.user.set(this.default);
        this.permissions.clear();
    }

    setPermissions(role: IRbacRole): void {
        this.permissions.clear();

        role.permissions.forEach(permission => {
            const businessEntity = permission.businessEntity.toLowerCase();
            const permissionType = permission.permissionType.toLowerCase();

            if (!this.permissions.has(businessEntity)) {
                this.permissions.set(businessEntity, new Set());
            }
            this.permissions.get(businessEntity)?.add(permissionType);
        });
    }

    hasPermissionByEntityAndType(entity: string, type: string): boolean {
        return (
            (this.permissions.has(entity) &&
                this.permissions.get(entity)?.has(type)) ||
            false
        );
    }

    hasPermission(permission: string): boolean {
        const [entity, type] = permission.toLowerCase().split('.');

        if (!entity || !type) {
            return false;
        }

        return this.hasPermissionByEntityAndType(entity, type);
    }

    getPermissionsForEntity(entity: string): Set<string> | undefined {
        return this.permissions.get(entity);
    }
}
