import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import {
    KpiValueChange,
    RagPanelComponent,
    RagPanelType,
} from './rag-panel/rag-panel.component';
import { QueueService } from '@services/queue/queue.service';
import { IKpiThreshold, ITelXLKpiThreshold } from '@models/rbac/kpi';
import { RbacApiService } from '@services/api/rbac/rbac.api.service';
import { AggregationLevel } from '@models/data/aggregate-level';
import { UserService } from '@services/user/user.service';
import {
    DialogButton,
    DialogModalComponent,
} from '@components/shared/dialog/dialog.component';

@Component({
    selector: 'rag-status',
    standalone: true,
    imports: [CommonModule, RagPanelComponent],
    templateUrl: './rag-status.component.html',
    styleUrl: './rag-status.component.scss',
})
export class RagStatusComponent implements OnInit {
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();
    RagPanelType = RagPanelType;
    current = this.breadcrumbService.current;
    telXLThreshold?: ITelXLKpiThreshold;
    threshold?: IKpiThreshold;

    slaThreshold!: KpiValueChange;
    maxLongestWaitTimeSeconds!: KpiValueChange;
    maxConversationsWaiting!: KpiValueChange;
    averageWaitTimeSeconds!: KpiValueChange;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private queueService: QueueService,
        private rbacApiService: RbacApiService,
        private userService: UserService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        if (this.idToKPIId) {
            this.rbacApiService
                .getKpiThresholds(this.idToKPIId, this.aggregateToKPIEntity)
                .subscribe({
                    next: kpiThresholds => {
                        this.slaThreshold = {
                            lowValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.slaThresholdLow ??
                                this.current()?.level === AggregationLevel.Queue
                                    ? kpiThresholds?.kpiThreshold
                                          ?.slaThreshold ?? null
                                    : null,
                            highValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.slaThresholdHigh ??
                                this.current()?.level === AggregationLevel.Queue
                                    ? kpiThresholds?.kpiThreshold
                                          ?.slaThreshold ?? null
                                    : null,
                            isValid: true,
                        };
                        this.maxLongestWaitTimeSeconds = {
                            lowValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.maxLongestWaitTimeLowSeconds ?? null,
                            highValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.maxLongestWaitTimeHighSeconds ?? null,
                            isValid: true,
                        };
                        this.maxConversationsWaiting = {
                            lowValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.maxConversationsLowWaiting ?? null,
                            highValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.maxConversationsHighWaiting ?? null,
                            isValid: true,
                        };
                        this.averageWaitTimeSeconds = {
                            lowValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.averageWaitTimeLowSeconds ?? null,
                            highValue:
                                kpiThresholds?.kpiTelXLThreshold
                                    ?.averageWaitTimeHighSeconds ?? null,
                            isValid: true,
                        };

                        this.threshold = kpiThresholds?.kpiThreshold;
                        this.telXLThreshold =
                            kpiThresholds?.kpiTelXLThreshold ??
                            this.assignFromLegacy(kpiThresholds?.kpiThreshold);
                    },
                });
        }
    }

    onSlaThresholdChange(value: KpiValueChange) {
        this.slaThreshold = value;
    }

    onMaxLongestWaitTimeSecondsChange(value: KpiValueChange) {
        this.maxLongestWaitTimeSeconds = value;
    }

    onMaxConversationsWaitingChange(value: KpiValueChange) {
        this.maxConversationsWaiting = value;
    }

    onAverageWaitTimeSecondsChange(value: KpiValueChange) {
        this.averageWaitTimeSeconds = value;
    }

    save() {
        const modalRef = this.modalService.open(DialogModalComponent, {
            centered: true,
            keyboard: false,
            backdrop: 'static',
        });

        modalRef.componentInstance.title = 'Save Changes';
        modalRef.componentInstance.icon = 'fa-circle-question';
        modalRef.componentInstance.message =
            'Are you sure you want to save these changes?';
        modalRef.componentInstance.buttons = [
            {
                label: 'Cancel',
                cssClass: 'btn-outline-secondary ps-5 pe-5 pt-1 pb-1',
                returnValue: false,
            },
            {
                label: 'Save',
                cssClass: 'btn-dark ps-5 pe-5 pt-1 pb-1',
                returnValue: true,
            },
        ] as DialogButton<boolean>[];

        modalRef.componentInstance.dialogResult.subscribe((result: boolean) => {
            if (result) {
                this.rbacApiService
                    .setKpiThresholds(
                        this.idToKPIId as string,
                        this.aggregateToKPIEntity,
                        this.threshold,
                        this.calculateValues
                    )
                    .subscribe({
                        next: () => {
                            if (
                                this.current()?.level === AggregationLevel.Queue
                            ) {
                                this.queueService.setKpi(
                                    this.idToKPIId as string,
                                    this.calculateValues
                                );
                            }

                            this.close();
                        },
                    });
            }
            modalRef.close();
        });
    }

    close() {
        this.closed.emit();
    }

    get canSave(): boolean {
        const newValue = this.calculateValues;

        if (
            (this.slaThreshold && !this.slaThreshold.isValid) ||
            (this.maxLongestWaitTimeSeconds &&
                !this.maxLongestWaitTimeSeconds) ||
            (this.maxConversationsWaiting && !this.maxConversationsWaiting) ||
            (this.averageWaitTimeSeconds && !this.averageWaitTimeSeconds)
        ) {
            return false;
        }

        return (
            newValue.averageWaitTimeHighSeconds !==
                this.telXLThreshold?.averageWaitTimeHighSeconds ||
            newValue.averageWaitTimeLowSeconds !==
                this.telXLThreshold?.averageWaitTimeLowSeconds ||
            newValue.slaThresholdLow !== this.telXLThreshold?.slaThresholdLow ||
            newValue.slaThresholdHigh !==
                this.telXLThreshold?.slaThresholdHigh ||
            newValue.maxConversationsLowWaiting !==
                this.telXLThreshold?.maxConversationsLowWaiting ||
            newValue.maxConversationsHighWaiting !==
                this.telXLThreshold?.maxConversationsHighWaiting ||
            newValue.maxLongestWaitTimeLowSeconds !==
                this.telXLThreshold?.maxLongestWaitTimeLowSeconds ||
            newValue.maxLongestWaitTimeHighSeconds !==
                this.telXLThreshold?.maxLongestWaitTimeHighSeconds
        );
    }

    private get calculateValues(): ITelXLKpiThreshold {
        return {
            slaThresholdLow:
                this.slaThreshold?.lowValue !== null
                    ? this.slaThreshold?.lowValue
                    : undefined,
            slaThresholdHigh:
                this.slaThreshold?.highValue !== null
                    ? this.slaThreshold?.highValue
                    : undefined,
            maxConversationsLowWaiting:
                this.maxConversationsWaiting?.lowValue !== null
                    ? this.maxConversationsWaiting?.lowValue
                    : undefined,
            maxConversationsHighWaiting:
                this.maxConversationsWaiting?.highValue !== null
                    ? this.maxConversationsWaiting?.highValue
                    : undefined,
            maxLongestWaitTimeLowSeconds:
                this.maxLongestWaitTimeSeconds?.lowValue !== null
                    ? this.maxLongestWaitTimeSeconds?.lowValue
                    : undefined,
            maxLongestWaitTimeHighSeconds:
                this.maxLongestWaitTimeSeconds?.highValue !== null
                    ? this.maxLongestWaitTimeSeconds?.highValue
                    : undefined,
            averageWaitTimeLowSeconds:
                this.averageWaitTimeSeconds?.lowValue !== null
                    ? this.averageWaitTimeSeconds?.lowValue
                    : undefined,
            averageWaitTimeHighSeconds:
                this.averageWaitTimeSeconds?.highValue !== null
                    ? this.averageWaitTimeSeconds?.highValue
                    : undefined,
        };
    }

    private assignFromLegacy(
        legacy?: IKpiThreshold
    ): ITelXLKpiThreshold | undefined {
        if (
            this.current()?.level !== AggregationLevel.Queue ||
            legacy === undefined
        )
            return;

        return {
            slaThresholdLow: legacy.slaThreshold,
            slaThresholdHigh: legacy.slaThreshold,
            maxConversationsLowWaiting: legacy.maxConversationsWaiting,
            maxConversationsHighWaiting: legacy.maxConversationsWaiting,
            averageWaitTimeLowSeconds: legacy.averageWaitTimeSeconds,
            averageWaitTimeHighSeconds: legacy.averageWaitTimeSeconds,
            maxLongestWaitTimeLowSeconds: legacy.maxLwtSeconds,
            maxLongestWaitTimeHighSeconds: legacy.maxLwtSeconds,
        };
    }

    private get idToKPIId(): string | undefined {
        switch (this.current()?.level) {
            case AggregationLevel.Tenant:
                return this.userService.user().tenantId;
            default:
                return this.current()?.id;
        }
    }

    private get aggregateToKPIEntity(): string {
        switch (this.current()?.level) {
            case AggregationLevel.Tenant:
                return 'tenants';
            case AggregationLevel.BusinessUnit:
                return 'businessunits';
            case AggregationLevel.Queue:
                return 'queues';
            default:
                return '';
        }
    }
}
