import { IMetric } from '@models/metrics/metric';
import { Queue } from '@models/queue/queue';
import { ITelXLKpiThreshold } from '@models/rbac/kpi';

export const NBU_NAME = 'No Business Unit';
export const NBU_ID = 'nobussinessunit';

export interface IBusinessUnit {
    id: string;
    name: string;
    kpiTelXLThreshold?: ITelXLKpiThreshold;
}

export interface IBusinessUnitPerformace {
    answeredWithinSLAPercentage: number;
    answeredWithinSLAPercentageMetric: IMetric;
    answeredWithinSLAPercentageMetricByQueue: IMetric[];
    waiting: number;
    waitingMetric: IMetric;
    connected: number;
    success: number;
    complete: number;
    timedOut: number;
    abandoned: number;
    outOfSla: number;
    /*
       
    avgWaitingTimeInSeconds: number;
    avgWaitingTimeInSecondsMetric: IMetric;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: number;
    longestWaitingTimeInSecondsMetric: IMetric;
    
    */
}

export class BusinessUnit implements IBusinessUnit {
    id: string;
    name: string;
    queues: Queue[];
    kpiTelXLThreshold?: ITelXLKpiThreshold;

    constructor(
        id: string,
        name: string,
        queues: Queue[],
        kpiTelXLThreshold?: ITelXLKpiThreshold
    ) {
        this.id = id;
        this.name = name;
        this.queues = queues;
        this.kpiTelXLThreshold = kpiTelXLThreshold;
    }

    get isConfigured(): boolean {
        return this.configuredQueues.length > 0;
    }

    get configuredQueues(): Queue[] {
        return this.queues.filter(q => q.isConfigured);
    }

    get performance(): IBusinessUnitPerformace {
        if (this.configuredQueues.length === 0) {
            return {
                answeredWithinSLAPercentageMetricByQueue: [],
                answeredWithinSLAPercentageMetric: {} as IMetric,
                answeredWithinSLAPercentage: 0,
                waiting: 0,
                waitingMetric: {} as IMetric,
                connected: 0,
                success: 0,
                complete: 0,
                timedOut: 0,
                abandoned: 0,
                outOfSla: 0,
            };
        }

        return {
            answeredWithinSLAPercentageMetricByQueue: this.configuredQueues.map(
                q => q.performance.answeredWithinSLAPercentageMetric
            ),
            answeredWithinSLAPercentage: Math.round(
                this.configuredQueues.reduce((total: number, queue: Queue) => {
                    return (
                        total + queue.performance.answeredWithinSLAPercentage
                    );
                }, 0) / this.configuredQueues.length
            ),
            answeredWithinSLAPercentageMetric: {
                percent: Math.round(
                    this.configuredQueues.reduce(
                        (total: number, queue: Queue) => {
                            return (
                                total +
                                queue.performance.answeredWithinSLAPercentage
                            );
                        },
                        0
                    ) / this.configuredQueues.length
                ),
                state: [
                    Math.round(
                        (this.kpiTelXLThreshold?.slaThresholdLow ?? 1) * 100
                    ),
                    Math.round(
                        (this.kpiTelXLThreshold?.slaThresholdHigh ?? 1) * 100
                    ),
                ],
            },
            waiting: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.waiting;
                },
                0
            ),
            waitingMetric: this.kpiTelXLThreshold?.maxConversationsHighWaiting
                ? {
                      percent: Math.round(
                          (this.configuredQueues.reduce(
                              (total: number, queue: Queue) => {
                                  return total + queue.performance.waiting;
                              },
                              0
                          ) /
                              (this.kpiTelXLThreshold
                                  ?.maxConversationsHighWaiting ?? 1)) *
                              100
                      ),

                      state: [
                          Math.round(
                              ((this.kpiTelXLThreshold
                                  ?.maxConversationsLowWaiting ?? 1) /
                                  (this.kpiTelXLThreshold
                                      ?.maxConversationsHighWaiting ?? 1)) *
                                  100
                          ),
                          100,
                      ],
                  }
                : { percent: 0, state: [100, 100] },
            connected: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.connected;
                },
                0
            ),
            success: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.success;
                },
                0
            ),
            complete: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.complete;
                },
                0
            ),
            timedOut: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.timedOut;
                },
                0
            ),
            abandoned: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.abandoned;
                },
                0
            ),
            outOfSla: this.configuredQueues.reduce(
                (total: number, queue: Queue) => {
                    return total + queue.performance.outOfSla;
                },
                0
            ),
        };
    }
}
