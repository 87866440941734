import { PresenceState } from '../presence/user-presence';

export const AgentStates = ['Busy', 'Break', 'Idle', 'LoggedOut'] as const;
export type AgentState = (typeof AgentStates)[number];

export type ChannelType =
    | ''
    | 'webChat'
    | 'voice'
    | 'twitter'
    | 'instagram'
    | 'virtual'
    | 'whatsApp'
    | 'sms';

export type WorkItemState =
    | 'LookUp'
    | 'Preview'
    | 'Connected'
    | 'Afterwork'
    | 'Closed';

export const MediaTypes = ['Webchat', 'Voice', 'Email', 'Messaging'] as const;
export type MediaType = (typeof MediaTypes)[number];

export type ManualAssignResult = 'Ok' | 'NotFound' | 'NotStaffed';

export enum TaskOrder {
    Voice = 1,
    Webchat = 2,
    Messaging = 3,
    Email = 4,
}

export interface ICompleteWorkItemParams {
    workItemId: string;
    closureCode: string;
    notes: string;
}
export interface IAgentProperties {
    id: string;
    state: PresenceState;
    stateSince: Date;
    breakName: string;
    capabilities: string[];
    displaySettings: {
        hideSensitiveAgentInformation: boolean;
        hideExternalContacts: boolean;
    };
}

export interface IWorkItem {
    workItemId: string;
    conversations: IConversations;
    businessUnitId: string | null;
    customerId: string;
    workItemState: WorkItemState;
    lastStateChangeDate: string;
    createdAt: string;
    primaryConversationId: string;
    usableClosureCodes: string[];
    channelId: number;
    primaryMediaType: MediaType;
    primaryChannelType: ChannelType;
}

export interface IConversations {
    conversationId: string;
    channelType: ChannelType;
}

interface ITimeStamp {
    timeStamp: string;
}

export interface IAgentClientState {
    agentProperties: IAgentProperties;
    workItems: IWorkItem[];
    timeStamp: ITimeStamp;
}

export interface IAgentStateTransitionRequested {
    toState: string;
    initiatedBy: string;
}

export interface IMessage {
    id: number;
    timeStamp: string;
    text: string;
    link: '';
}
