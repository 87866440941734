import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/api/auth/auth.service';
import { NotificationHubService } from '@services/signalr/blenderGara/notification/notification.hub.service';
import { SideNavComponent } from '@components/side-nav/side-nav.component';
import { MainLayoutComponent } from '@components/main-layout/main-layout.component';
import { HeaderComponent } from '@components/header/header.component';
import { NotificationModalComponent } from '@components/notification-modal/notification-modal.component';
import { NotificationToastComponent } from '@components/notification-toast/notification-toast.component';
import { UserService } from '@services/user/user.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        SideNavComponent,
        MainLayoutComponent,
        HeaderComponent,
        NotificationToastComponent,
    ],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
    private notificationSubscription!: Subscription;
    isCollapsed = false;
    showModal = false;
    user = this.userService.user.asReadonly();
    notification = this.notificationHubService.notificationAlert;

    constructor(
        private modalService: NgbModal,
        private authService: AuthService,
        private userService: UserService,
        private notificationHubService: NotificationHubService
    ) {}

    ngOnInit() {
        this.authService.initializeAuthentication();

        this.notificationSubscription =
            this.notificationHubService.notificationAlert.subscribe(
                notification => {
                    if (notification) {
                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                centered: true,
                                keyboard: false,
                                backdrop: 'static',
                            }
                        );

                        modalRef.componentInstance.notification = notification;
                        modalRef.componentInstance.show();
                    } else {
                        this.modalService.dismissAll();
                    }
                }
            );
    }

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    sideNavMargin(): string[] {
        return this.isCollapsed ? ['m-2'] : ['ms-3', 'mt-2', 'mb-2', 'me-3'];
    }

    ngOnDestroy() {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
    }
}
