<div class="h-100 d-flex flex-column">
    <breadcrumb [showTitle]="true"></breadcrumb>
    <div class="mt-auto d-flex align-items-left pb-2">
        <input type="checkbox" id="All" name="All" value="All" checked="true" />
        <i class="fa-light fa-phone ms-3"></i>
        <i class="fa-light fa-message ms-3"></i>
        <i class="fa-light fa-envelope ms-3"></i>
        <i class="fa-light fa-share-nodes ms-3"></i>
    </div>
</div>
