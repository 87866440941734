<div class="modal-header">
    <i class="fa-lg fa-circle-exclamation fa-solid me-4"></i> 
    <h4 class="modal-title">{{ notification.title }}</h4>    
</div>
<div class="modal-body pt-0 pb-0">
    <p>{{ notification.description }}</p>
</div>
<div class="modal-footer pt-1">
    <button type="button" class="btn btn-outline-secondary ps-5 pe-5 pt-1 pb-1" (click)="dismissNotification(notification)" [disabled]="!enabled">Reject</button>
    <button type="button" class="btn btn-dark ps-5 pe-5 pt-1 pb-1" (click)="actionNotification(notification)" [disabled]="!enabled">Accept @if(hasExpiry){<span class="ms-2 badge bg-secondary">{{ countdown }}</span>}</button>
</div>
  