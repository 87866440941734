import { Component, computed, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RingsComponent } from '@components/shared/metrics/rings/rings.component';
import { ProgressComponent } from '@components/shared/metrics/progress/progress.component';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { BusinessUnitService } from '@services/business-unit/business-unit.service';
import { BusinessUnit } from '@models/business-unit/business-unit';

@Component({
    selector: 'business-unit-header-zone',
    standalone: true,
    imports: [CommonModule, RingsComponent, ProgressComponent],
    templateUrl: './business-unit-header-zone.component.html',
    styleUrl: './business-unit-header-zone.component.scss',
})
export class BusinessUnitHeaderZoneComponent {
    @Input({ required: true }) isExpanded!: boolean;
    current = this.breadcrumbService.current;
    businessunit = computed<BusinessUnit | undefined>(
        () =>
            this.businessUnitService.businessUnits
                .asReadonly()()
                .find(bu => bu.id == this.current()?.id) as BusinessUnit
    );

    constructor(
        private breadcrumbService: BreadcrumbService,
        private businessUnitService: BusinessUnitService
    ) {}

    get ringsWidth() {
        return this.isExpanded ? '225x' : '225px';
    }

    get ringsHeight() {
        return this.isExpanded ? '150px' : '80px';
    }
}
