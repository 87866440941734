@for(notification of tier2(); track notification.id; let last = $last) {
    <ngb-toast [autohide]="false" id=`notification-toast-card-${notification.id}`>
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <h5 class="offcanvas-title bold pe-3">{{ notification.title }}</h5>
                <p>{{ notification.created | date : "HH:mm - dd/MM/YYYY" }}</p>
            </div>
            <button
                type="button"
                class="btn-close text-reset btn-tiny"
                aria-label="Close"
                (click)="dismissNotification(notification)"
            ></button>            
        </div>
        @if(last) {
            {{ notification.description }}
            <div class="d-flex justify-content-end">
                <button class="btn btn-sm btn-outline-secondary action ps-3 pe-3 pt-1 pb-1" (click)="actionNotification(notification)">Action</button>
            </div>
        }
    </ngb-toast>
}
    