import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { StateService } from '@services/state/state.service';
import { LoggerService } from '@services/logger/logger.service';
import { UserService } from '@services/user/user.service';
import { IUser } from '@models/auth/user';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private stateService: StateService,
        private userService: UserService,
        private logger: LoggerService
    ) {}

    private onLogin(userData: any) {
        this.userService.update({
            id: userData.sub,
            name: userData.name,
            emailAddress: userData.email,
            authenticated: true,
        } as IUser);

        this.logger.debug('User information', userData);

        this.stateService.onLogin();
    }

    private onLogoff() {
        this.userService.clear();
        this.stateService.onLogoff();
    }

    initializeAuthentication() {
        this.oidcSecurityService
            .checkAuth()
            .subscribe(({ isAuthenticated, userData, accessToken }) => {
                this.logger.info(
                    `Authentication initialization the user is ${
                        isAuthenticated ? '' : 'not '
                    }authenticated.`
                );

                if (isAuthenticated) {
                    this.onLogin(userData);
                    this.logger.debug('Access token', accessToken);
                } else {
                    this.onLogoff();
                }
            });
    }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.oidcSecurityService.logoff().subscribe(() => {
            this.onLogoff();
        });
    }
}
