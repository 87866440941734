import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Segment } from '@services/breadcrumb/breadcrumb.service';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { Queue } from '@models/queue/queue';
import { AggregationLevel } from '@models/data/aggregate-level';

@Component({
    selector: 'data-item-panel',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './data-item-panel.component.html',
    styleUrl: './data-item-panel.component.scss',
})
export class DataItemPanelComponent {
    AggregationLevel = AggregationLevel;
    @Input() segment!: Segment | undefined;
    @Input({ required: true }) data!: BusinessUnit | Queue;

    isBusinessUnit(data: BusinessUnit | Queue): data is BusinessUnit {
        return (data as BusinessUnit).queues !== undefined;
    }

    isQueue(data: BusinessUnit | Queue): data is Queue {
        return (data as Queue).businessUnit !== undefined;
    }

    get link(): string {
        return this.segment?.url ? `${this.segment.url}/${this.data.id}` : '';
    }
}
