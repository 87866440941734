<div class="row">
    @switch (current()?.level) { @case(AggregationLevel.Tenant) {
    @for(businessUnit of tenant()?.configuredBusinessUnits; track
    businessUnit.id) { @if(businessUnit.configuredQueues.length) {
    <div class="col-3">
        <data-item-panel
            [data]="businessUnit"
            [segment]="current()"
        ></data-item-panel>
    </div>
    } } } @case(AggregationLevel.BusinessUnit) { @for(queue of
    businessUnit?.configuredQueues; track queue.id) {
    <div class="col-3">
        <data-item-panel [data]="queue" [segment]="current()"></data-item-panel>
    </div>
    } } }
</div>
