import { Route } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AuthCallbackComponent } from '@components/auth-callback/auth-callback.component';
import { SilentRenewComponent } from '@components/silent-renew/silent-renew.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { PerformanceComponent } from '@components/performance/performance.component';
import { DebugComponent } from '@components/debug/debug.component';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'performance',
        component: PerformanceComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
        children: [
            { path: '', component: PerformanceComponent },
            { path: ':businessUnit', component: PerformanceComponent },
            { path: ':businessUnit/:queue', component: PerformanceComponent },
        ],
    },
    {
        path: 'debug',
        component: DebugComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    },
    {
        path: 'silent-Renew',
        component: SilentRenewComponent,
    },
];
