<li
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="active"
    #rla="routerLinkActive"
    class="nav-item"
    id="side-nav-item-{{ label | lowercase | replaceSpaces }}"
>
    <button
        class="btn btn-primary p-3 w-100"
        type="button"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLinkActive="active"
        [routerLink]="routerLink"
        [ngClass]="{ open: !isCollapsed }"
        id="side-nav-item-button-{{ label | lowercase | replaceSpaces }}"
    >
        <i
            [class]="iconName"
            class="fa-lg"
            [ngClass]="rla.isActive ? 'fa-solid' : 'fa-light'"
        ></i>
        @if(!isCollapsed) {
        <span
            class="ms-3"
            id="side-nav-item-button-text-{{
                label | lowercase | replaceSpaces
            }}"
        >
            {{ label }}
        </span>
        }
    </button>
</li>
