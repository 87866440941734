<div class="d-flex align-items-center h-100">
    <table class="table w-100">
        <tbody>
            <tr>
                <td>
                    <progressbar
                        label="SLA"
                        [metric]="
                            queue()?.performance
                                ?.answeredWithinSLAPercentageMetric
                        "
                        [overall]="
                            (
                                queue()?.performance
                                    ?.answeredWithinSLAPercentage ?? 0
                            ).toString()
                        "
                        [percentage]="true"
                    ></progressbar>
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="queue()?.performance?.waitingMetric"
                        [overall]="
                            (queue()?.performance?.waiting ?? 0).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Connected"
                        [overall]="
                            (queue()?.performance?.connected ?? 0).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Current LWT"
                        [overall]="currentLongestWaitingTime"
                        [metric]="currentLongestWaitingTimeMetric"
                    />
                </td>
                <td>
                    <progressbar
                        label="WT Longest"
                        [overall]="longestWaitTime"
                        [metric]="
                            queue()?.performance
                                ?.longestWaitingTimeInSecondsMetric
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="WT Average"
                        [overall]="averageWaitTime"
                        [metric]="
                            queue()?.performance?.avgWaitingTimeInSecondsMetric
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Success"
                        [overall]="
                            (queue()?.performance?.success ?? 0).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Abandoned"
                        [overall]="
                            (queue()?.performance?.abandoned ?? 0).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Completed"
                        [overall]="
                            (queue()?.performance?.complete ?? 0).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Out of SLA"
                        [overall]="
                            (queue()?.performance?.outOfSla ?? 0).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Timed Out"
                        [overall]="
                            (queue()?.performance?.timedOut ?? 0).toString()
                        "
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
