import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '@core/environments/environment';
import { SignalRHubService } from '@signalr/signalr.hub.service';
import { LoggerService } from '@services/logger/logger.service';
import { QueueService } from '@services/queue/queue.service';
import { AgentService } from '@services/agent/agent.service';
import { IAgentReport } from '@models/agent/agent';
import { IQueueReport } from '@models/queue/queue';

@Injectable({
    providedIn: 'root',
})
export class AggregationsHubService extends SignalRHubService {
    constructor(
        private queueService: QueueService,
        private agentService: AgentService,
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Aggregation',
            `${environment.aggregationsUrl}/today/hub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveTodaysAgentReportData',
            (agentReport: IAgentReport) => {
                this.messageReceived();

                this.logger.debug(
                    'Aggregation Service (ReceiveTodaysAgentReportData) ->',
                );
                this.logger.table(agentReport);

                this.agentService.setReport(agentReport);
            },
        );

        this.hubConnection.on(
            'ReceiveTodaysQueueData',
            (queueReport: IQueueReport) => {
                this.messageReceived();

                this.logger.debug(
                    'Aggregation Service (ReceiveTodaysQueueData) ->',
                );
                this.logger.table(queueReport);

                this.queueService.setReport(queueReport);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveTodaysAgentReportData');
        this.hubConnection.off('ReceiveTodaysQueueData');
    }
}
