import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, share, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { LoggerService } from '@services/logger/logger.service';
import { ITeam } from '@models/rbac/teams';
import { IKpiThreshold, ITelXLKpiThreshold } from '@models/rbac/kpi';
import { IRbacUser } from '@models/rbac/user';
import { IRbacTenant, IUserTenant } from '@models/rbac/tenant';
import { IBusinessUnit } from '@models/business-unit/business-unit';

interface IKpiThresholdResult {
    thresholds: string;
    telXLThresholds: string;
}

@Injectable({
    providedIn: 'root',
})
export class RbacApiService {
    constructor(private logger: LoggerService, private http: HttpClient) {}

    getTeams(userId: string): Observable<ITeam[]> {
        return this.http
            .get<ITeam[]>(`${environment.rbacUrl}/teams/bymember/${userId}`)
            .pipe(
                tap({
                    next: teams => {
                        this.logger.debug(
                            'Rbac Service (Get Teams) -> Successful',
                            teams
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Teams) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    getKpiThresholds(
        id: string,
        entity: string
    ): Observable<{
        kpiThreshold: IKpiThreshold;
        kpiTelXLThreshold: ITelXLKpiThreshold;
    } | null> {
        return this.http
            .get<IKpiThresholdResult>(
                `${environment.rbacUrl}/kpiThresholds/${entity}/${id}`
            )
            .pipe(
                map(data =>
                    data !== null
                        ? {
                              kpiThreshold: JSON.parse(
                                  data.thresholds
                              ) as IKpiThreshold,
                              kpiTelXLThreshold: JSON.parse(
                                  data.telXLThresholds
                              ) as ITelXLKpiThreshold,
                          }
                        : null
                ),
                tap({
                    next: kpiThreshold => {
                        this.logger.debug(
                            'Rbac Service (Get KPI Thresholds) -> Successful',
                            kpiThreshold
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get KPI Thresholds) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    setKpiThresholds(
        id: string,
        entity: string,
        kpiThreshold?: IKpiThreshold,
        kpiTelXLThreshold?: ITelXLKpiThreshold
    ) {
        const payload = {
            thresholds: kpiThreshold ? JSON.stringify(kpiThreshold) : null,
            telXLThresholds: kpiTelXLThreshold
                ? JSON.stringify(kpiTelXLThreshold)
                : null,
        };

        return this.http
            .post<void>(
                `${environment.rbacUrl}/kpiThresholds/${entity}/${id}`,
                payload
            )
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Rbac Service (Set KPI Thresholds) -> Successful',
                            { id, kpiThreshold, kpiTelXLThreshold }
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Set KPI Thresholds) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    getUser(userId: string): Observable<IRbacUser> {
        return this.http
            .get<IRbacUser>(`${environment.rbacUrl}/users/${userId}`)
            .pipe(
                tap({
                    next: user => {
                        this.logger.debug(
                            'Rbac Service (Get User) -> Successful',
                            user
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get User) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    getConnectedUsers(userId: string): Observable<IUserTenant[]> {
        return this.http
            .get<IUserTenant[]>(
                `${environment.rbacUrl}/users/${userId}/connectedUsers`
            )
            .pipe(
                tap({
                    next: tenants => {
                        this.logger.debug(
                            'Rbac Service (Get Connected Users) -> Successful',
                            tenants
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Connected Users) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    getTenant(tenantId: string): Observable<IRbacTenant> {
        return this.http
            .get<IRbacTenant>(`${environment.rbacUrl}/tenant/${tenantId}`)
            .pipe(
                tap({
                    next: tenants => {
                        this.logger.debug(
                            'Rbac Service (Get Tenant) -> Successful',
                            tenants
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Tenant) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }

    getBusinessUnits(): Observable<IBusinessUnit[]> {
        return this.http
            .get<IBusinessUnit[]>(`${environment.rbacUrl}/businessUnits`)
            .pipe(
                tap({
                    next: businessUnits => {
                        this.logger.debug(
                            'Rbac Service (Get Business Unit) -> Successful',
                            businessUnits
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Business Unit) -> Failed',
                            error
                        );
                    },
                }),
                share()
            );
    }
}
