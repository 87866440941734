<div class="d-flex" #taskbelt>
    <task-belt-item  *ngFor="let task of incomingTasks()" #task id="{{task.mediaType}}-{{task.workItemId}}"
        (clicked)="deselectOthers(task.workItemId)" 
        [isSelected]="isSelected(task.workItemId)"
        [taskName]="task.mediaType"
        [description]="task.channelType"
        [createdAt]="task.createdAt"
        [order]="task.order"
        [icon]="task.icon"
        [iconSelected]="task.iconSelected"
        [selectedClass]="task.classSelected"
        [removing]="task.removing"
        [slideClass]="task.slideClass">
    </task-belt-item>  
</div>