import { Injectable, signal } from '@angular/core';
import { IUser } from '@models/auth/user';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    default: IUser = {
        id: '',
        name: '',
        emailAddress: '',
        authenticated: false,
        enabled: false,
        phoneNumber: '',
        profilePictureUrl: null,
        tenantId: '',
        displayName: '',
        jobTitle: '',
        tenants: [],
        tenant: undefined,
    };

    user = signal<IUser>(this.default);

    update(user: IUser) {
        this.user.set({ ...this.user(), ...user });
    }

    clear() {
        this.user.set(this.default);
    }
}
