<div>
    <span class="d-flex">
        <i class="fa-house fa-light"></i>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb font-small">
                @for(breadcrumb of breadcrumbs(); track breadcrumb.url; let last
                = $last) {
                <li
                    class="breadcrumb-item"
                    [class.active]="last"
                    [class.full-width]="last"
                    [class.shrink]="!last"
                >
                    @if(last) {
                    {{ breadcrumb.label }}
                    } @else{
                    <a [routerLink]="breadcrumb.url">
                        {{ breadcrumb.label }}
                    </a>
                    }
                </li>
                }
            </ol>
        </nav>
    </span>
    @if(showTitle) {
    <div class="font-large weight-heavy">{{ title }}</div>
    }
</div>
