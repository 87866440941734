import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';

@Component({
    selector: 'dashboard',
    standalone: true,
    imports: [CommonModule, AngularSplitModule],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {}
