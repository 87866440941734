import { BusinessUnit } from '@models/business-unit/business-unit';
import { IMetric } from '@models/metrics/metric';
import { ITelXLKpiThreshold } from '@models/rbac/kpi';

export interface ITenant {
    id: string;
    name: string;
    kpiTelXLThreshold?: ITelXLKpiThreshold;
}

export interface ITenantPerformace {
    answeredWithinSLAPercentage: number;
    answeredWithinSLAPercentageMetricByBusinessUnit: IMetric[];
    waiting: number;
    waitingMetric: IMetric;
    connected: number;
    success: number;
    complete: number;
    timedOut: number;
    abandoned: number;
    outOfSla: number;

    /*
    avgWaitingTimeInSeconds: number;
    avgWaitingTimeInSecondsMetric: IMetric;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: number;
    longestWaitingTimeInSecondsMetric: IMetric;
    
    */
}

export class Tenant implements ITenant {
    id: string;
    name: string;
    businessUnits: BusinessUnit[];
    kpiTelXLThreshold?: ITelXLKpiThreshold;

    constructor(
        id: string,
        name: string,
        businessUnits: BusinessUnit[],
        kpiTelXLThreshold?: ITelXLKpiThreshold
    ) {
        this.id = id;
        this.name = name;
        this.businessUnits = businessUnits;
        this.kpiTelXLThreshold = kpiTelXLThreshold;
    }

    get configuredBusinessUnits(): BusinessUnit[] {
        return this.businessUnits.filter(bu => bu.isConfigured);
    }

    get performance(): ITenantPerformace {
        if (this.configuredBusinessUnits.length === 0) {
            return {
                answeredWithinSLAPercentageMetricByBusinessUnit: [],
                answeredWithinSLAPercentage: 0,
                waiting: 0,
                waitingMetric: {} as IMetric,
                connected: 0,
                success: 0,
                complete: 0,
                timedOut: 0,
                abandoned: 0,
                outOfSla: 0,
            };
        }

        return {
            answeredWithinSLAPercentageMetricByBusinessUnit:
                this.configuredBusinessUnits.map(
                    bu => bu.performance.answeredWithinSLAPercentageMetric
                ),
            answeredWithinSLAPercentage: Math.round(
                this.configuredBusinessUnits.reduce(
                    (total: number, businessUnit: BusinessUnit) => {
                        return (
                            total +
                            businessUnit.performance.answeredWithinSLAPercentage
                        );
                    },
                    0
                ) / this.configuredBusinessUnits.length
            ),
            waiting: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.waiting;
                },
                0
            ),
            waitingMetric: this.kpiTelXLThreshold?.maxConversationsHighWaiting
                ? {
                      percent: Math.round(
                          (this.configuredBusinessUnits.reduce(
                              (total: number, businessUnit: BusinessUnit) => {
                                  return (
                                      total + businessUnit.performance.waiting
                                  );
                              },
                              0
                          ) /
                              (this.kpiTelXLThreshold
                                  ?.maxConversationsHighWaiting ?? 1)) *
                              100
                      ),

                      state: [
                          Math.round(
                              ((this.kpiTelXLThreshold
                                  ?.maxConversationsLowWaiting ?? 1) /
                                  (this.kpiTelXLThreshold
                                      ?.maxConversationsHighWaiting ?? 1)) *
                                  100
                          ),
                          100,
                      ],
                  }
                : { percent: 0, state: [100, 100] },
            connected: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.connected;
                },
                0
            ),
            success: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.success;
                },
                0
            ),
            complete: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.complete;
                },
                0
            ),
            outOfSla: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.outOfSla;
                },
                0
            ),
            timedOut: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.timedOut;
                },
                0
            ),
            abandoned: this.configuredBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return total + businessUnit.performance.abandoned;
                },
                0
            ),
        };
    }
}
