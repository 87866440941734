<div class="row">
    <div class="col-md-12">
        <div class="container mt-3">
            <div class="row justify-content-center">
                <div class="col-md-1 icon-container">
                    <button
                        type="button"
                        class="btn"
                        (click)="setFilter('all')"
                        (mouseenter)="setHoveredFilter('all')"
                        (mouseleave)="setHoveredFilter(null)"
                    >
                        <i
                            [class]="
                                getFilterButtonIcon('all') + ' fa-square-check'
                            "
                            [ngClass]="{
                                textsuccess:
                                    isFilterButtonHoveredOrSelected('all')
                            }"
                        ></i>
                    </button>
                    <br />
                    <span *ngIf="isFilterButtonHoveredOrSelected('all')">
                        &nbsp; &nbsp; All
                    </span>
                </div>
                <div class="col-md-1 icon-container">
                    <button
                        type="button"
                        class="btn"
                        (click)="setFilter('connected')"
                        (mouseenter)="setHoveredFilter('connected')"
                        (mouseleave)="setHoveredFilter(null)"
                    >
                        <i
                            [class]="
                                getFilterButtonIcon('connected') + ' fa-link '
                            "
                        ></i>
                    </button>
                    <br />
                    <span *ngIf="isFilterButtonHoveredOrSelected('connected')">
                        Connected
                    </span>
                </div>
                <div class="col-md-1 icon-container">
                    <button
                        type="button"
                        class="btn"
                        (click)="setFilter('waiting')"
                        (mouseenter)="setHoveredFilter('waiting')"
                        (mouseleave)="setHoveredFilter(null)"
                    >
                        <i
                            [class]="
                                getFilterButtonIcon('waiting') + ' fa-timer '
                            "
                        ></i>
                    </button>
                    <br />
                    <span *ngIf="isFilterButtonHoveredOrSelected('waiting')">
                        Waiting
                    </span>
                </div>
                <div class="col-md-1 icon-container">
                    <button
                        type="button"
                        class="btn"
                        (click)="setFilter('completed')"
                        (mouseenter)="setHoveredFilter('completed')"
                        (mouseleave)="setHoveredFilter(null)"
                    >
                        <i
                            [class]="
                                getFilterButtonIcon('completed') +
                                ' fa-badge-check '
                            "
                        ></i>
                    </button>
                    <br />
                    <span *ngIf="isFilterButtonHoveredOrSelected('completed')">
                        Completed
                    </span>
                </div>
                <div class="col-md-1 icon-container">
                    <button
                        type="button"
                        class="btn"
                        (click)="setFilter('abandoned')"
                        (mouseenter)="setHoveredFilter('abandoned')"
                        (mouseleave)="setHoveredFilter(null)"
                    >
                        <i
                            [class]="
                                getFilterButtonIcon('abandoned') +
                                ' fa-circle-xmark '
                            "
                        ></i>
                    </button>
                    <br />
                    <span *ngIf="isFilterButtonHoveredOrSelected('abandoned')">
                        Abandoned
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-3">
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>Status</th>
                    <th>Initiated Time</th>
                    <th>Customer Name</th>
                    <th>
                        Customer
                        <br />
                        Identifier
                    </th>
                    <th>Agent Name</th>
                    <th>
                        Connected Time
                        <br />
                        /Duration
                    </th>
                    <th>Wait Time</th>
                    <th>Self Service Time</th>
                    <th>Total Time</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let activity of activities | sort : column : order;
                        trackBy: trackByFn
                    "
                >
                    <td class="text-center">
                        <i
                            [class]="
                                getStatusIcon(activity.status.toLowerCase())
                            "
                        ></i>
                    </td>
                    <td class="text-center">
                        {{ activity.queued | date : 'dd/MM/yy HH:mm:ss' }}
                    </td>
                    <td>{{ activity.customer.name }}</td>
                    <td>{{ activity.customer.id }}</td>
                    <td>{{ activity.agent.name }}</td>
                    <td class="text-center">
                        {{
                            duration(activity.connected, activity.closed ?? now)
                        }}
                    </td>
                    <td class="text-center">
                        {{
                            duration(activity.queued, activity.deQueued ?? now)
                        }}
                    </td>
                    <td class="text-center">
                        {{ selfService(activity) }}
                    </td>
                    <td class="text-center">
                        {{ duration(activity.started, activity.ended ?? now) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
