/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
} from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggerService } from '@services/logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export abstract class SignalRHubService {
    protected hubConnection!: HubConnection;

    constructor(
        private name: string,
        private url: string,
        private oidcSecurityService: OidcSecurityService,
        protected logger: LoggerService
    ) {}

    start(parameters?: Record<string, string[]>) {
        this.oidcSecurityService
            .getAccessToken()
            .pipe(take(1))
            .subscribe(token => {
                this.hubConnection = new HubConnectionBuilder()
                    .withUrl(`${this.url}${this.parseParameters(parameters)}`, {
                        accessTokenFactory: () => token,
                    })
                    .withAutomaticReconnect()
                    .build();

                this.hubConnection.onreconnected(() =>
                    this.logger.info(
                        `${this.name} Service -> Reconnected successfully`
                    )
                );

                this.hubConnection.onclose(() =>
                    this.logger.info(
                        `${this.name} Service -> Connection closed`
                    )
                );

                this.hubConnection
                    .start()
                    .then(() => {
                        this.onStart();
                        this.logger.info(
                            `${this.name} Service -> Connection started`
                        );
                    })
                    .catch(error =>
                        this.logger.error(
                            `${this.name} Service -> Error while starting connection`,
                            error
                        )
                    );

                this.registerHandlers();
            });
    }

    stop() {
        if (this.hubConnection) {
            this.unregisterHandlers();

            this.hubConnection
                .stop()
                .then(() => {
                    this.onStop();
                    this.logger.info(
                        `${this.name} Service -> Connection stopped`
                    );
                })
                .catch(error =>
                    this.logger.error(
                        `${this.name} Service -> Error while stopping connection`,
                        error
                    )
                );
        }
    }

    get state(): HubConnectionState {
        return this.hubConnection
            ? this.hubConnection.state
            : HubConnectionState.Disconnected;
    }

    private parseParameters(parameters?: Record<string, string[]>): string {
        if (parameters) {
            return `?${Object.keys(parameters)
                .map(key => `${key}=${parameters[key].join(',')}`)
                .join('&')}`;
        }

        return '';
    }

    protected onStart() {}
    protected onStop() {}
    protected registerHandlers() {}
    protected unregisterHandlers() {}
}
