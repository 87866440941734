<div class="container-fluid">
    <div class="row me-1">
        <div class="col-12 task-belt">
            <task-belt></task-belt>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="main-container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
