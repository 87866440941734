<div class="h-100 pt-3 pb-3">
    <div class="d-flex justify-content-between">
        <div class="font-medium weight-medium title">{{ title }}</div>
        <div class="form-check form-switch">
            <input
                class="form-check-input"
                [ngClass]="{
                    'bg-primary border-primary': isEnabled,
                    'bg-secondary border-secondary': !isEnabled
                }"
                type="checkbox"
                role="switch"
                [(ngModel)]="isEnabled"
            />
        </div>
    </div>
    @switch (ragPanelType) { @case(RagPanelType.Numeric) {
    <div class="col-4 mt-2">
        <spinner-input
            title="Low"
            [isEnabled]="isEnabled"
            [minValue]="min"
            [maxValue]="max"
            [value]="lowValue"
            [error]="!isValid"
            (valueChange)="onLowValueChange($event)"
        />
    </div>

    <div class="col-4 mt-2">
        <spinner-input
            title="High"
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="100"
            [value]="highValue"
            [error]="!isValid"
            (valueChange)="onHighValueChange($event)"
        />
    </div>
    } @case(RagPanelType.Percentage) {
    <div class="col-4 mt-2">
        <spinner-input
            title="Low"
            [isEnabled]="isEnabled"
            [minValue]="min"
            [maxValue]="max"
            [value]="lowValue"
            [error]="!isValid"
            placeholder="%"
            (valueChange)="onLowValueChange($event)"
        />
    </div>

    <div class="col-4 mt-2">
        <spinner-input
            title="High"
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="100"
            [value]="highValue"
            [error]="!isValid"
            placeholder="%"
            (valueChange)="onHighValueChange($event)"
        />
    </div>
    } @case(RagPanelType.Time) {
    <div class="w-100 mt-2 d-flex justify-content-between gap-4">
        <spinner-input
            title="Low"
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="23"
            [value]="lowValueHours"
            [error]="!isValid"
            placeholder="H"
            [twoDigit]="true"
            (valueChange)="
                onLowValueTimeChange({ type: 'H', newValue: $event })
            "
        />
        <spinner-input
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="59"
            placeholder="M"
            [twoDigit]="true"
            [value]="lowValueMinutes"
            [error]="!isValid"
            (valueChange)="
                onLowValueTimeChange({ type: 'M', newValue: $event })
            "
        />
        <spinner-input
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="59"
            placeholder="S"
            [twoDigit]="true"
            [value]="lowValueSeconds"
            [error]="!isValid"
            (valueChange)="
                onLowValueTimeChange({ type: 'S', newValue: $event })
            "
        />
    </div>

    <div class="w-100 mt-2 d-flex justify-content-between gap-4">
        <spinner-input
            title="High"
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="23"
            placeholder="H"
            [twoDigit]="true"
            [value]="highValueHours"
            [error]="!isValid"
            (valueChange)="
                onHighValueTimeChange({ type: 'H', newValue: $event })
            "
        />
        <spinner-input
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="59"
            placeholder="M"
            [twoDigit]="true"
            [value]="highValueMinutes"
            [error]="!isValid"
            (valueChange)="
                onHighValueTimeChange({ type: 'M', newValue: $event })
            "
        />
        <spinner-input
            [isEnabled]="isEnabled"
            [minValue]="0"
            [maxValue]="59"
            placeholder="S"
            [twoDigit]="true"
            [value]="highValueSeconds"
            [error]="!isValid"
            (valueChange)="
                onHighValueTimeChange({ type: 'S', newValue: $event })
            "
        />
    </div>
    } }
</div>
