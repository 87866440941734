<div class="d-flex align-items-center h-100">
    <table class="table w-100">
        <tbody>
            <tr>
                <td
                    [style.width]="ringsWidth"
                    [attr.rowspan]="isExpanded ? 2 : null"
                >
                    <rings
                        [metric]="
                            businessunit()?.performance
                                ?.answeredWithinSLAPercentageMetricByQueue ?? []
                        "
                        label="SLA"
                        [overall]="
                            (
                                businessunit()?.performance
                                    ?.answeredWithinSLAPercentage ?? 0
                            ).toString()
                        "
                        [width]="ringsWidth"
                        [height]="ringsHeight"
                        [percentage]="true"
                    />
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="businessunit()?.performance?.waitingMetric"
                        [overall]="
                            (
                                businessunit()?.performance?.waiting ?? 0
                            ).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Connected"
                        [overall]="
                            (
                                businessunit()?.performance?.connected ?? 0
                            ).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="{ percent: 80, state: [25, 75] }"
                        overall="80"
                    />
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="{ percent: 80, state: [25, 75] }"
                        overall="80"
                    />
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="{ percent: 80, state: [25, 75] }"
                        overall="80"
                    />
                </td>
                <td>
                    <progressbar
                        label="Success"
                        [overall]="
                            (
                                businessunit()?.performance?.success ?? 0
                            ).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Abandoned"
                        [overall]="
                            (
                                businessunit()?.performance?.abandoned ?? 0
                            ).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Completed"
                        [overall]="
                            (
                                businessunit()?.performance?.complete ?? 0
                            ).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Out of SLA"
                        [overall]="
                            (
                                businessunit()?.performance?.outOfSla ?? 0
                            ).toString()
                        "
                    />
                </td>
                <td>
                    <progressbar
                        label="Timed Out"
                        [overall]="
                            (
                                businessunit()?.performance?.timedOut ?? 0
                            ).toString()
                        "
                    />
                </td>
            </tr>
            @if(isExpanded) {
            <tr>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="{ percent: 80, state: [25, 75] }"
                        overall="80"
                    />
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="{ percent: 80, state: [25, 75] }"
                        overall="80"
                    />
                </td>
                <td>
                    <progressbar
                        label="Waiting"
                        [metric]="{ percent: 80, state: [25, 75] }"
                        overall="80"
                    />
                </td>
                <td colspan="4"></td>
            </tr>
            }
        </tbody>
    </table>
</div>
