<div class="container-fluid">
    <div class="row">
        <div class="col-12 border rounded p-2 bg-primary">
            <header-zone></header-zone>
        </div>
    </div>

    <div class="row">
        <div class="col-8 border rounded p-2 bg-primary">
            @if(current()?.level === AggregationLevel.Queue) {
            <queue-table-panel></queue-table-panel>
            } @else {
            <data-panel></data-panel>
            }
        </div>
        <div class="col-4 border rounded p-2 bg-primary">Teams / Agent</div>
    </div>
</div>
