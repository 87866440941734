<div class="h-100vh full-width">  
       
    @if(user().authenticated) {                
        <header class="w-100"></header>
        <div class="d-flex flex-row h-fitscreen">
            <div class="side-nav" [ngClass]="sideNavMargin()">
                <side-nav (toggleCollapse)="toggleCollapse()" [isCollapsed]="isCollapsed" />
            </div>
            <div class="flex-grow-1 main-layout">
                <main-layout></main-layout>
            </div>
        </div>
    }
    <notification-toasts></notification-toasts>
</div>
