import { Injectable, signal } from '@angular/core';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { Queue } from '@models/queue/queue';
import { RbacApiService } from '@api/rbac/rbac.api.service';
import { TenantService } from '@services/tenant/tenant.service';
import { IRbacBusinessUnit } from '@models/rbac/business-unit';
import { ITelXLKpiThreshold } from '@models/rbac/kpi';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    businessUnits = signal<BusinessUnit[]>([]);

    constructor(
        private rbacApiService: RbacApiService,
        private tenantService: TenantService,
    ) {}

    set(businessUnits: IRbacBusinessUnit[]) {
        this.businessUnits.set(
            businessUnits
                .map(bu => new BusinessUnit(bu.id, bu.name, []))
                .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                ),
        );

        this.businessUnits
            .asReadonly()()
            .forEach(businessUnit =>
                this.rbacApiService
                    .getKpiThresholds(businessUnit.id, 'businessunits')
                    .subscribe({
                        next: kpiThresholds => {
                            if (kpiThresholds) {
                                businessUnit.kpiTelXLThreshold =
                                    kpiThresholds.kpiTelXLThreshold;

                                const businessUnitUpdate = this.businessUnits
                                    .asReadonly()()
                                    .map(bu =>
                                        bu.id === bu.id ? bu : businessUnit,
                                    );

                                this.businessUnits.set(businessUnitUpdate);
                            }
                        },
                    }),
            );
    }

    getKpi(businessUnitId: string): ITelXLKpiThreshold {
        return (
            this.businessUnits
                .asReadonly()()
                .find(bu => bu.id === businessUnitId) as BusinessUnit
        ).kpiTelXLThreshold;
    }

    setKpi(businessUnitId: string, telXLKpiThreshold: ITelXLKpiThreshold) {
        const businessUnitUpdate = this.businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === businessUnitId
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          bu.queues,
                          telXLKpiThreshold,
                      )
                    : bu,
            );

        this.businessUnits.set(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }

    updateQueue(queue: Queue) {
        if (!queue.businessUnit || !queue.name || queue.name.length === 0)
            return;

        const businessUnit = this.businessUnits
            .asReadonly()()
            .find(bu => bu.id === queue.businessUnit);

        const queues = [
            ...(businessUnit?.queues.filter(q => q.id !== queue.id) ?? []),
            ...[queue],
        ].sort((a, b) =>
            a.name && b.name
                ? a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                : 0,
        );

        const businessUnitUpdate = this.businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === queue.businessUnit
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          queues,
                          bu.kpiTelXLThreshold,
                      )
                    : bu,
            );

        this.businessUnits.set(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }
}
