<div class="modal-header">
    <i class="fa-lg fa-solid me-4" [ngClass]="icon"></i>
    <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body pt-0 pb-0">
    <p>{{ message }}</p>
</div>
<div class="modal-footer pt-1">
    @for(button of buttons; track button.label) {
    <button
        class="btn"
        type="button"
        [ngClass]="button.cssClass"
        (click)="handleButtonClick(button.returnValue)"
    >
        {{ button.label }}
    </button>
    }
</div>
