import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HubConnectionState } from '@microsoft/signalr';
import { QueueService } from '@services/queue/queue.service';
import { AggregationsHubService } from '@services/signalr/data/aggregations/aggregations.hub.service';
import { LiveHubService } from '@services/signalr/data/live/live.hub.service';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { UserService } from '@services/user/user.service';
import { HeaderZoneComponent } from './header-zone/header-zone.component';
import { DataPanelComponent } from './data-panel/data-panel.component';
import { QueueTableComponent } from './queue-table/queue-table.component';
import { AggregationLevel } from '@models/data/aggregate-level';

@Component({
    selector: 'performance',
    standalone: true,
    imports: [
        CommonModule,
        HeaderZoneComponent,
        RouterModule,
        DataPanelComponent,
        QueueTableComponent,
    ],
    templateUrl: './performance.component.html',
    styleUrl: './performance.component.scss',
})
export class PerformanceComponent implements OnInit, OnDestroy {
    AggregationLevel = AggregationLevel;
    isDirty = this.queueService.isDirty;
    queues = this.queueService.queues.asReadonly();
    user = this.userService.user;
    current = this.breadcrumbService.current;

    constructor(
        private liveHubService: LiveHubService,
        private aggregationsHubService: AggregationsHubService,
        private queueService: QueueService,
        private userService: UserService,
        private breadcrumbService: BreadcrumbService
    ) {
        effect(
            () => {
                if (this.isDirty() && this.queues().length > 0) {
                    if (
                        this.liveHubService.state ===
                        HubConnectionState.Connected
                    ) {
                        this.liveHubService.stop();
                    }

                    this.liveHubService.start({
                        queueIds: this.queues().map(q => q.id),
                    });

                    if (
                        this.aggregationsHubService.state ===
                        HubConnectionState.Connected
                    ) {
                        this.aggregationsHubService.stop();
                    }

                    this.aggregationsHubService.start({
                        queueIds: this.queues().map(q => q.id),
                        agentIds: [this.user().id],
                    });

                    this.isDirty.set(false);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit() {
        this.isDirty.set(true);
    }

    ngOnDestroy() {
        this.liveHubService.stop();
        this.aggregationsHubService.stop();
    }
}
