import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import {
    INotificationModel,
    NotificationActionType,
    NotificationType,
} from '@models/notification/notification';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationHubService } from '@services/signalr/blenderGara/notification/notification.hub.service';

@Component({
    selector: 'notification-panel',
    imports: [CommonModule],
    standalone: true,
    templateUrl: './notification-panel.component.html',
    styleUrl: './notification-panel.component.scss',
})
export class NotificationPanelComponent {
    private notifications = this.notificationHubService.notifications;
    tier3 = computed<INotificationModel[]>(() =>
        this.notifications().filter((n) => n.type === NotificationType.Tier3)
    );

    activeOffcanvas = inject(NgbActiveOffcanvas);

    constructor(private notificationHubService: NotificationHubService) {}

    dismissNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Dismissed
        );
    }

    actionNotification(notification: INotificationModel) {
        this.notificationHubService.markNotificationAs(
            notification,
            NotificationActionType.Actioned
        );
    }

    toggleNotificationPinned(notification: INotificationModel) {
        if (notification.pinned)
            this.notificationHubService.markNotificationAs(
                notification,
                NotificationActionType.Unpinned
            );
        else
            this.notificationHubService.markNotificationAs(
                notification,
                NotificationActionType.Pinned
            );
    }
}
