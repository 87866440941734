import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
    selector: 'title-panel',
    standalone: true,
    imports: [CommonModule, BreadcrumbComponent],
    templateUrl: './title-panel.component.html',
    styleUrl: './title-panel.component.scss',
})
export class TitlePanelComponent {}
