import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration } from 'chart.js';
import { getColourValue } from 'src/app/helpers/style';
import { IMetric } from '@models/metrics/metric';

/**
 * Component to display nested ring charts.
 * Each ring represents a percentage value with a colour code indicating the status.
 *
 * @selector rings
 * @standalone true
 * @component RingsComponent
 * @imports CommonModule, BaseChartDirective
 *
 * @Input metrics - Array of objects conforming to IMetric interface, each representing a ring in the chart.
 * @Input label - Text to display.
 * @Input overall - The summary value for the data.
 *
 * Usage:
 * <rings [metric]="data" [label]="label" [overall]="overall"></rings>
 *
 * Note:
 * A data item contains the percent value of the ring and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [25, 75]}
 * In this scenario a percent value over 25 will render amber state and over 75 will render red state.
 *
 * e.g. { percent: 30, state: [50]}
 * In this scenario a percent value over 50 will render amber state. No red state is configured.
 *
 */
@Component({
    selector: 'rings',
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    templateUrl: './rings.component.html',
    styleUrl: './rings.component.scss',
})
export class RingsComponent implements OnChanges {
    private red = getColourValue('--red-primary');
    private amber = getColourValue('--yellow-primary');
    private green = getColourValue('--green-primary');
    private background = getColourValue('--background');

    @Input() height!: string;
    @Input() width!: string;
    @Input() label!: string;
    @Input() overall!: string;
    @Input() metric: IMetric[] = [];
    @Input() percentage = false;
    @ViewChild(BaseChartDirective) private chart!: BaseChartDirective;

    public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        cutout: this.cutout,
        responsive: true,
        animation: false,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    public doughnutChartData: ChartConfiguration<'doughnut'>['data'] = {
        datasets: [],
    };

    public doughnutChartType: ChartConfiguration<'doughnut'>['type'] =
        'doughnut';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['metric']) {
            this.updateChartData();
            this.chart?.chart?.update();
        }
    }

    private updateChartData() {
        this.doughnutChartData.datasets = this.metric.flatMap(
            (value, index) => {
                const ring = {
                    data: [value.percent, 100 - value.percent],
                    backgroundColor: [
                        this.translateColour(value),
                        this.background,
                    ],
                    hoverBackgroundColor: [
                        this.translateColour(value),
                        this.background,
                    ],
                    borderWidth: 0,
                    borderRadius: 20,
                };

                const blank = {
                    data: [100, 0],
                    backgroundColor: ['#FFFFFFFF', '#FFFFFFFF'],
                    hoverBackgroundColor: ['#FFFFFFFF', '#FFFFFFFF'],
                    borderWidth: 0,
                    borderRadius: 0,
                    weight: 0.5,
                };

                if (index < this.metric.length - 1) return [ring, blank];
                else return [ring];
            }
        );
    }

    private translateColour(value: IMetric): string {
        if (
            value &&
            value.state &&
            value.state.length == 2 &&
            value.percent > value.state[1]
        ) {
            return this.red;
        }
        if (
            value &&
            value.state &&
            value.state.length > 0 &&
            value.percent > value.state[0]
        ) {
            return this.amber;
        }

        return this.green;
    }

    private get cutout(): string {
        return `${60 - this.metric.length * 10}%`;
    }
}
