import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { TenantService } from '@services/tenant/tenant.service';
import { AggregationLevel } from '@models/data/aggregate-level';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { DataItemPanelComponent } from '../data-item-panel/data-item-panel.component';

@Component({
    selector: 'data-panel',
    standalone: true,
    imports: [CommonModule, DataItemPanelComponent],
    templateUrl: './data-panel.component.html',
    styleUrl: './data-panel.component.scss',
})
export class DataPanelComponent {
    AggregationLevel = AggregationLevel;
    current = this.breadcrumbService.current;
    tenant = this.tenantService.tenant.asReadonly();

    constructor(
        private breadcrumbService: BreadcrumbService,
        private tenantService: TenantService
    ) {}

    get businessUnit(): BusinessUnit | undefined {
        if (this.current()?.level === AggregationLevel.BusinessUnit) {
            return this.tenant()?.businessUnits.find(
                bu => bu.id === this.current()?.id
            ) as BusinessUnit;
        }

        return;
    }
}
