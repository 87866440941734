import { Injectable, signal } from '@angular/core';
import { RbacApiService } from '@services/api/rbac/rbac.api.service';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { ITenant, Tenant } from '@models/tenant/tenant';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    tenant = signal<Tenant | undefined>(undefined);

    constructor(private rbacApiService: RbacApiService) {}

    set(tenant: ITenant) {
        this.tenant.set(new Tenant(tenant.id, tenant.name, []));

        this.rbacApiService.getKpiThresholds(tenant.id, 'tenants').subscribe({
            next: kpiThresholds => {
                if (kpiThresholds) {
                    this.tenant.set(
                        new Tenant(
                            tenant.id,
                            tenant.name,
                            [],
                            kpiThresholds.kpiTelXLThreshold
                        )
                    );
                }
            },
        });
    }

    updateBusinessUnits(businessUnits: BusinessUnit[]) {
        const tenent = this.tenant.asReadonly()();

        if (tenent) {
            this.tenant.set(
                new Tenant(
                    tenent?.id,
                    tenent?.name,
                    businessUnits,
                    tenent.kpiTelXLThreshold
                )
            );
        }
    }
}
