import { Injectable, signal } from '@angular/core';
import { environment } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggerService } from '@services/logger/logger.service';
import { SignalRHubService } from '@services/signalr/signalr.hub.service';
import { QueueService } from '@services/queue/queue.service';
import { IAgentReport } from '@models/data/agent-report';
import { IQueueReport } from '@models/queue/queue';

@Injectable({
    providedIn: 'root',
})
export class AggregationsHubService extends SignalRHubService {
    todaysAgentReportData = signal<IAgentReport>({} as IAgentReport);

    constructor(
        private queueService: QueueService,
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService
    ) {
        super(
            'Aggregation',
            `${environment.aggregationsUrl}/today/hub`,
            oidcSecurityService,
            logger
        );
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveTodaysAgentReportData',
            (todaysAgentReportData: IAgentReport) => {
                this.logger.debug(
                    'Aggregation Service (ReceiveTodaysAgentReportData) ->'
                );
                this.logger.table(todaysAgentReportData);

                this.todaysAgentReportData.set(todaysAgentReportData);
            }
        );

        this.hubConnection.on(
            'ReceiveTodaysQueueData',
            (queueReport: IQueueReport) => {
                this.logger.debug(
                    'Aggregation Service (ReceiveTodaysQueueData) ->'
                );
                this.logger.table(queueReport);

                this.queueService.setReport(queueReport);
            }
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveTodaysAgentReportData');
        this.hubConnection.off('ReceiveTodaysQueueData');
    }
}
