import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { TaskBeltComponent } from '@components/task-belt/task-belt.component';

@Component({
    selector: 'main-layout',
    standalone: true,
    imports: [CommonModule, HeaderComponent, RouterModule, TaskBeltComponent],
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {}
