<div
    class="h-100 d-flex flex-column align-items-start"
    [ngClass]="isCollapsed ? 'collapsed' : 'open'"
    id="side-nav-container"
>
    <div class="w-100 task-row" id="side-nav-live-tasks">
        <span class="my-live-tasks"></span>
    </div>

    <ul
        class="nav nav-pills flex-column mt-2 mb-auto w-100"
        id="side-nav-bar-container"
    >
        @for (link of links; track link.label) {
            @if (canRender(link.permission)) {
                <side-nav-item
                    [iconName]="link.icon"
                    [label]="link.label"
                    [routerLink]="link.routeLink"
                    [isCollapsed]="isCollapsed"
                    class="mb-2"
                    id="side-nav-bar-pill-{{
                        link.label | lowercase | replaceSpaces
                    }}"
                />
            }
        }

        <side-nav-item
            iconName="fa-sign-out"
            label="Logout"
            [isCollapsed]="isCollapsed"
            class="mb-2"
            (click)="logout()"
            id="side-nav-bar-pill-logout"
        />
    </ul>

    <ul class="nav nav-pills flex-column mt-2 w-100">
        <side-nav-item
            [iconName]="
                isCollapsed ? 'fa-arrow-right-to-line' : 'fa-arrow-left-to-line'
            "
            label="Minimise"
            [isCollapsed]="isCollapsed"
            (click)="onToggleCollapse()"
            id="side-nav-bar-pill-minimise"
        />
    </ul>
</div>
