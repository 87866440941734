<div *ngIf="isBusinessUnit(data)">
    <a [routerLink]="link" class="text-decoration-none">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">{{ data.name }}</h5>
                <p class="card-text">
                    @for(queue of data.queues; track queue.id) {
                        <p>{{queue.name}}</p>
                    }
                </p>
            </div>
        </div>
    </a>
</div>

<div *ngIf="isQueue(data)">
    <a [routerLink]="link" class="text-decoration-none">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">{{ data.name }}</h5>
                <p class="card-text">                    
                        {{data.name}}
                </p>
            </div>
        </div>
    </a>
</div>