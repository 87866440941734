import { Injectable } from '@angular/core';
import { Subscription, switchMap, timer } from 'rxjs';
import { environment } from '@environments/environment';
import {
    calculateUserPresence,
    PresenceState,
} from '@models/blenderGara/presence/user-presence';
import { PresenceApiService } from '@services/api/blenderGara/presence/presence.api.service';
import { RbacApiService } from '@services/api/rbac/rbac.api.service';
import { AgentHubService } from '@services/signalr/blenderGara/agent/agent.hub.service';
import { NotificationHubService } from '@services/signalr/blenderGara/notification/notification.hub.service';
import { UserService } from '@services/user/user.service';
import { QueueService } from '@services/queue/queue.service';
import { BusinessUnitService } from '@services/business-unit/business-unit.service';
import { TenantService } from '@services/tenant/tenant.service';
import { IUser } from '@models/auth/user';
import { NBU_ID, NBU_NAME } from '@models/business-unit/business-unit';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private subscription: Subscription = new Subscription();
    user = this.userService.user.asReadonly();

    constructor(
        private agentHubService: AgentHubService,
        private notificationHubService: NotificationHubService,
        private presenceApiService: PresenceApiService,
        private rbacApiService: RbacApiService,
        private userService: UserService,
        private queueService: QueueService,
        private businessUnitService: BusinessUnitService,
        private tenantService: TenantService
    ) {}

    onLogin() {
        this.presenceApiService.login().subscribe({
            complete: () => {
                this.agentHubService.start();
                this.notificationHubService.start();
                this.presenceApiService.getBreakNames();

                this.subscription = timer(0, environment.pollingInterval * 1000)
                    .pipe(
                        switchMap(() =>
                            this.rbacApiService.getTeams(this.user().id)
                        )
                    )
                    .subscribe({
                        next: teams => {
                            this.queueService.addOrUpdate(
                                teams
                                    ? [
                                          ...new Set(
                                              teams.reduce(
                                                  (
                                                      accumulator,
                                                      currentValue
                                                  ) => [
                                                      ...accumulator,
                                                      ...currentValue.queueIds,
                                                  ],
                                                  [] as string[]
                                              )
                                          ),
                                      ]
                                    : []
                            );
                        },
                    });

                this.rbacApiService.getUser(this.user().id).subscribe({
                    next: user => {
                        this.userService.update(user as IUser);

                        this.rbacApiService
                            .getTenant(this.user().tenantId)
                            .subscribe({
                                next: tenant => {
                                    this.userService.update({
                                        tenant,
                                    } as IUser);

                                    this.tenantService.set({
                                        id: tenant.id,
                                        name: tenant.name,
                                    });
                                },
                            });
                    },
                });

                this.rbacApiService
                    .getConnectedUsers(this.user().id)
                    .subscribe({
                        next: tenants => {
                            this.userService.update({ tenants } as IUser);
                        },
                    });

                this.rbacApiService.getBusinessUnits().subscribe({
                    next: businessUnits => {
                        businessUnits.push({
                            name: NBU_NAME,
                            id: NBU_ID,
                        });
                        this.businessUnitService.set(businessUnits);
                    },
                });
            },
        });
    }

    onLoginAndActivate() {
        this.presenceApiService.login().subscribe({
            complete: () => {
                this.agentHubService.start();
                this.notificationHubService.start();
                this.presenceApiService.activate();
            },
        });
    }

    onActivate() {
        this.presenceApiService.activate();
    }

    onLogoff() {
        this.presenceApiService.logout().subscribe({
            complete: () => {
                this.agentHubService.presence.set(
                    calculateUserPresence(
                        PresenceState.LoggedOut,
                        new Date(Date.now())
                    )
                );
                this.agentHubService.stop();
                this.notificationHubService.stop();
            },
        });

        this.subscription.unsubscribe();
    }
}
