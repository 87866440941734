import { Component, computed, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserService } from '@services/user/user.service';
import {
    Segment,
    BreadcrumbService,
} from '@services/breadcrumb/breadcrumb.service';
import { BusinessUnitService } from '@services/business-unit/business-unit.service';
import { QueueService } from '@services/queue/queue.service';
import { AggregationLevel } from '@models/data/aggregate-level';

@Component({
    selector: 'breadcrumb',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
    @Input() showTitle = false;
    user = this.userService.user.asReadonly();
    businessUnits = this.businessUnitService.businessUnits.asReadonly();
    queues = this.queueService.queues.asReadonly();

    breadcrumbs = computed<Segment[]>(() => {
        return (
            this.breadcrumbService.segments()?.map(s => {
                return {
                    ...s,
                    label: this.getBreadcrumbLabel(s.level, s.id),
                } as Segment;
            }) ?? []
        );
    });

    constructor(
        private userService: UserService,
        private businessUnitService: BusinessUnitService,
        private queueService: QueueService,
        private breadcrumbService: BreadcrumbService
    ) {}

    private getBreadcrumbLabel(level: AggregationLevel, id: string): string {
        switch (level) {
            case AggregationLevel.Tenant:
                return this.user().tenant?.name ?? '';

            case AggregationLevel.BusinessUnit:
                return (
                    this.businessUnits()?.find(bu => bu.id === id)?.name ?? ''
                );

            case AggregationLevel.Queue:
                return this.queues().find(q => q.id === id)?.name ?? '';

            default:
                return '';
        }
    }

    get title(): string {
        if (this.breadcrumbs().length > 0)
            return this.breadcrumbs()[this.breadcrumbs().length - 1].label;
        else return '';
    }
}
