import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantService } from '@services/tenant/tenant.service';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { ProgressComponent } from '@components/shared/metrics/progress/progress.component';
import { RingsComponent } from '@components/shared/metrics/rings/rings.component';

@Component({
    selector: 'tenant-header-zone',
    standalone: true,
    imports: [CommonModule, RingsComponent, ProgressComponent],
    templateUrl: './tenant-header-zone.component.html',
    styleUrl: './tenant-header-zone.component.scss',
})
export class TenantHeaderZoneComponent {
    @Input({ required: true }) isExpanded!: boolean;
    current = this.breadcrumbService.current;
    tenant = this.tenantService.tenant;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private tenantService: TenantService
    ) {}

    get ringsWidth() {
        return this.isExpanded ? '225x' : '225px';
    }

    get ringsHeight() {
        return this.isExpanded ? '150px' : '80px';
    }
}
