import { Component, computed, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormatDurationPipe } from '@pipes/format-duration/format-duration.pipe';
import { SortPipe } from '@pipes/sort/sort.pipe';
import { secondsToTimeDifference } from 'src/app/helpers/time';
import { QueueService } from '@services/queue/queue.service';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { QueueActivity } from '@models/queue/queue-activity';

@Component({
    selector: 'queue-table-panel',
    standalone: true,
    imports: [CommonModule, SortPipe],
    providers: [FormatDurationPipe],
    templateUrl: './queue-table.component.html',
    styleUrl: './queue-table.component.scss',
})
export class QueueTableComponent implements OnInit, OnDestroy {
    private intervalSubscription: Subscription | undefined;
    activeFilter = 'all';
    column = 'queued';
    order: 'asc' | 'desc' = 'asc';
    hoveredFilter: string | null = null;
    now = new Date();
    current = this.breadcrumbService.current;
    queueActivities = computed<QueueActivity[]>(
        () =>
            this.queueService.queues
                .asReadonly()()
                .find(q => q.id === this.current()?.id)?.activity ?? []
    );

    constructor(
        private breadcrumbService: BreadcrumbService,
        private queueService: QueueService,
        private formatDurationPipe: FormatDurationPipe
    ) {}

    ngOnInit() {
        this.intervalSubscription = interval(1000).subscribe(() => {
            this.now = new Date();
        });
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    duration(start?: Date, end?: Date): string {
        if (start && end) {
            return this.formatDurationPipe.transform(
                secondsToTimeDifference(
                    Math.round((end.getTime() - start.getTime()) / 1000)
                )
            );
        }

        return '';
    }

    selfService(activity: QueueActivity): string {
        const conversationLength = Math.round(
            ((activity.ended ? activity.ended.getTime() : this.now.getTime()) -
                activity.started.getTime()) /
                1000
        );
        const timeInQueue = Math.round(
            ((activity.deQueued
                ? activity.deQueued.getTime()
                : this.now.getTime()) -
                activity.queued.getTime()) /
                1000
        );
        const timeInAgent = Math.round(
            ((activity.closed
                ? activity.closed.getTime()
                : this.now.getTime()) -
                (activity.connected
                    ? activity.connected.getTime()
                    : this.now.getTime())) /
                1000
        );

        return this.formatDurationPipe.transform(
            secondsToTimeDifference(
                conversationLength - timeInQueue - timeInAgent
            )
        );
    }

    setFilter(status: string): void {
        this.activeFilter = status;
    }

    getStatusIcon(status: string): string {
        switch (status) {
            case 'all':
                return 'fa-light fa-square-check';
            case 'connected':
                return 'fa-light fa-link';
            case 'waiting':
                return 'fa-light fa-timer';
            case 'completed':
                return 'fa-light fa-badge-check';
            case 'abandoned':
                return 'fa-light fa-circle-xmark';
            default:
                return '';
        }
    }

    getFilterButtonIcon(status: string): string {
        return this.activeFilter === status || this.hoveredFilter === status
            ? 'fa-solid'
            : 'fa-light';
    }

    setHoveredFilter(status: string | null): void {
        this.hoveredFilter = status;
    }

    isFilterButtonHoveredOrSelected(status: string): boolean {
        return this.activeFilter === status || this.hoveredFilter === status;
    }

    trackByFn(index: number, item: QueueActivity) {
        return item.id;
    }

    get activities(): QueueActivity[] {
        return this.queueActivities().filter(
            a =>
                a.status.toLowerCase() === this.activeFilter ||
                this.activeFilter === 'all'
        );
    }
}
