<div
    class="row"
    [ngClass]="{
        'size-expanded':
            isRAGStatusPanelVisible ||
            (isExpanded && current()?.level !== AggregationLevel.Queue),
        'size-normal':
            !isRAGStatusPanelVisible &&
            (!isExpanded || current()?.level === AggregationLevel.Queue)
    }"
>
    <div class="col-3 border-end">
        <title-panel></title-panel>
    </div>
    @if(!isRAGStatusPanelVisible) {
    <div class="col-8">
        @switch (current()?.level) { @case(AggregationLevel.Queue) {
        <queue-header-zone />
        } @case(AggregationLevel.BusinessUnit) {
        <business-unit-header-zone [isExpanded]="isExpanded" />
        } @case(AggregationLevel.Tenant) {
        <tenant-header-zone [isExpanded]="isExpanded" />
        }}
    </div>
    <div class="col-1 d-flex align-items-center">
        <div
            class="flex-column text-center header-zone-button"
            (click)="isRAGStatusPanelVisible = true"
        >
            <i class="fa-light fa-bars-progress fa-lg"></i>
            <div class="font-smaller">RAG Status</div>
        </div>
        @if(current()?.level !== AggregationLevel.Queue) {
        <div
            class="flex-column text-center header-zone-button ms-4"
            (click)="toggleExpandedView()"
        >
            <i
                class="fa-solid fa-sm"
                [ngClass]="{
                    'fa-chevron-down': !isExpanded,
                    'fa-chevron-up': isExpanded
                }"
            ></i>
        </div>
        }
    </div>
    } @else {
    <div class="col-9">
        <rag-status (closed)="onCloseRAGPanel()"></rag-status>
    </div>
    }
</div>
