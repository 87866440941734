<div class="h-100vh full-width">
    @if(loading()) {
    <div
        class="d-flex flex-column justify-content-center align-items-center vh-100"
    >
        <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
        >
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="mt-3">
            <p>Loading user details</p>
        </div>
    </div>
    } @else if(user().authenticated) {
    <header class="w-100"></header>
    <div class="d-flex flex-row h-fitscreen">
        <div class="side-nav" [ngClass]="sideNavMargin()">
            <side-nav
                (toggleCollapse)="toggleCollapse()"
                [isCollapsed]="isCollapsed"
            />
        </div>
        <div class="flex-grow-1 main-layout">
            <main-layout></main-layout>
        </div>
    </div>
    }
    <notification-toasts></notification-toasts>
</div>
