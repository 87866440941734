import {
    EnvironmentType,
    IEnvironmentSettings,
} from '@models/configuration/environment';
import { LogLevel } from '@models/configuration/log';

export const createEnvironmentSettings = (baseSettings: {
    production: boolean;
    logLevel: LogLevel;
    authority: string;
    apiBaseUrl: string;
    environment: EnvironmentType;
    scope: string;
    heartbeatInterval: number;
    pollingInterval: number;
}): IEnvironmentSettings => ({
    ...baseSettings,
    blenderUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/blender'
    }`,
    rbacUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/rbac'
    }`,
    liveUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/live'
    }`,
    aggregationsUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local
            ? ''
            : '/aggregations'
    }`,
});
