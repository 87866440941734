import { Component, computed, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { interval, Subscription } from 'rxjs';
import { secondsToTimeDifference } from 'src/app/helpers/time';
import { FormatDurationPipe } from '@pipes/format-duration/format-duration.pipe';
import { Queue } from '@models/queue/queue';
import { IMetric } from '@models/metrics/metric';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { QueueService } from '@services/queue/queue.service';
import { ProgressComponent } from '@components/shared/metrics/progress/progress.component';

@Component({
    selector: 'queue-header-zone',
    standalone: true,
    imports: [CommonModule, ProgressComponent],
    providers: [FormatDurationPipe],
    templateUrl: './queue-header-zone.component.html',
    styleUrl: './queue-header-zone.component.scss',
})
export class QueueHeaderZoneComponent implements OnInit, OnDestroy {
    private intervalSubscription: Subscription | undefined;
    current = this.breadcrumbService.current;
    queue = computed<Queue | undefined>(
        () =>
            this.queueService.queues
                .asReadonly()()
                .find(q => q.id == this.current()?.id) as Queue
    );
    currentLongestWaitingTime = '00:00';
    currentLongestWaitingTimeMetric: IMetric = { percent: 0, state: [] };

    constructor(
        private breadcrumbService: BreadcrumbService,
        private queueService: QueueService,
        private formatDurationPipe: FormatDurationPipe
    ) {}

    ngOnInit() {
        this.intervalSubscription = interval(1000).subscribe(() => {
            this.updateLongestWaitingTime();
        });
    }

    ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    get averageWaitTime() {
        return this.formatDurationPipe.transform(
            secondsToTimeDifference(
                this.queue()?.performance.avgWaitingTimeInSeconds ?? 0
            )
        );
    }

    get longestWaitTime() {
        return this.formatDurationPipe.transform(
            secondsToTimeDifference(
                this.queue()?.performance.longestWaitingTimeInSeconds ?? 0
            )
        );
    }

    private updateLongestWaitingTime() {
        const secondsElapsed = Math.round(
            (new Date().getTime() -
                (
                    this.queue()?.performance.currentLongestWaitingTimeStamp ??
                    new Date()
                ).getTime()) /
                1000
        );

        this.currentLongestWaitingTime = this.formatDurationPipe.transform(
            secondsToTimeDifference(secondsElapsed)
        );

        const longestWaitingTimeThreshold =
            this.queue()?.kpiTelXLThreshold?.maxLongestWaitTimeHighSeconds ?? 0;

        this.currentLongestWaitingTimeMetric = {
            percent: longestWaitingTimeThreshold
                ? Math.floor(
                      (secondsElapsed / longestWaitingTimeThreshold) * 100
                  )
                : 0,
            state: [
                Math.round(
                    ((this.queue()?.kpiTelXLThreshold
                        ?.maxLongestWaitTimeLowSeconds ?? 1) /
                        (this.queue()?.kpiTelXLThreshold
                            ?.maxLongestWaitTimeHighSeconds ?? 1)) *
                        100
                ),
                100,
            ],
        };
    }
}
