import {
    EnvironmentType,
    IEnvironmentSettings,
} from '@models/configuration/environment';
import { createEnvironmentSettings } from './environment.factory';
import { LogLevel } from '@models/configuration/log';

export const environment: IEnvironmentSettings = createEnvironmentSettings({
    production: false,
    logLevel: LogLevel.Information,
    environment: EnvironmentType.Dev,
    authority: 'https://auth-dev.telxl.net',
    apiBaseUrl: 'https://api-dev.telxl.net',
    heartbeatInterval: 30,
    pollingInterval: 60,
    scope: 'gum bp.access openid profile email',
});
