export enum PresenceState {
    Busy = 'Busy',
    Idle = 'Idle',
    LoggedOut = 'LoggedOut',
    Break = 'Break',
    PendingLogout = 'PendingLogout',
    PendingBreak = 'PendingBreak',
}

export interface IUserPresence {
    state: PresenceState;
    stateSince: Date;
    label: string;
    class: string;
    online: boolean;
}

export const calculateUserPresence = (
    state: PresenceState,
    stateSince: Date,
    breakName?: string
): IUserPresence => {
    return {
        state: state,
        stateSince: stateSince,
        label: stateTolabel(state, breakName),
        class: stateToClass(state),
        online: stateToOnline(state),
    };
};

const stateTolabel = (state: PresenceState, breakName?: string): string => {
    switch (state) {
        case PresenceState.LoggedOut:
            return 'Logged out';
        default:
            return state === PresenceState.Break && breakName
                ? breakName
                : state;
    }
};

const stateToClass = (state: PresenceState): string => {
    switch (state) {
        case PresenceState.Break:
            return 'warning';
        case PresenceState.Idle:
            return 'success';
        case PresenceState.Busy:
            return 'danger';
    }

    return 'secondary';
};

const stateToOnline = (state: PresenceState): boolean => {
    return state === PresenceState.Idle;
};
