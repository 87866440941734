import {
    Component,
    ViewChild,
    ElementRef,
    computed,
    AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { format, parseISO } from 'date-fns';
import { TaskBeltItemComponent } from '@components/task-belt-item/task-belt-item.component';
import { AgentHubService } from '@services/signalr/blenderGara/agent/agent.hub.service';
import { TaskOrder } from '@models/blenderGara/agent/agent-state';
import { IWorkItem } from '@models/blenderGara/agent/agent-state';
import {
    AnimationBuilder,
    trigger,
    style,
    animate,
    transition,
} from '@angular/animations';

interface IIncomingTask {
    order: number;
    workItemId: string;
    conversationId: string;
    mediaType: string;
    channelType: string;
    createdAt: string;
    createdAtActual: Date;
    icon: string;
    iconSelected: string;
    selected: boolean;
    classSelected: string;
    removing: boolean;
    slideClass: string;
}

@Component({
    selector: 'task-belt',
    standalone: true,
    imports: [CommonModule, RouterModule, TaskBeltItemComponent],
    templateUrl: './task-belt.component.html',
    styleUrl: './task-belt.component.scss',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX({{containerWidth()}}px)' }), // Dynamically set in component
                animate('300ms ease-in', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                animate(
                    '300ms ease-out',
                    style({ transform: 'translateX({{containerWidth()}}px)' })
                ),
            ]),
        ]),
    ],
})
export class TaskBeltComponent implements AfterViewInit {
    @ViewChild('taskbelt') container!: ElementRef;

    workItems = this.agentHubService.agentWorkItems;

    constructor(
        private agentHubService: AgentHubService,
        private builder: AnimationBuilder
    ) {}

    sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
    currentTasks: IWorkItem[] = [];
    displayedTasks: IIncomingTask[] = [];
    selectedItem: string | null = null;

    ngAfterViewInit() {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.addedNodes.length) {
                    mutation.addedNodes.forEach((node: any) => {
                        this.animateEnter(node);
                    });
                }
            });
        });
        observer.observe(this.container.nativeElement, {
            childList: true,
        });
    }

    incomingTasks = computed<IIncomingTask[]>(() => {
        if (
            Object.values(this.workItems()).length < this.displayedTasks.length
        ) {
            this.removeTask();
        }

        if (
            Object.values(this.workItems()).length > this.displayedTasks.length
        ) {
            Object.values(this.workItems()).forEach(workItem => {
                this.acceptIncomingTask(workItem);
            });
        }
        return this.displayedTasks;
    });

    checkDuplicateTask(task: IWorkItem): boolean {
        let exists = false;

        this.displayedTasks.forEach(value => {
            if (value.workItemId === task.workItemId) {
                exists = true;
            }
        });

        return exists;
    }

    removeTask() {
        this.displayedTasks.forEach((value, index) => {
            if (Object.values(this.workItems()).length > 0) {
                if (!this.signalItemExists(value.workItemId)) {
                    value.removing = true;
                    value.slideClass = 'animate__animated animate__fadeOutDown';
                    setTimeout(() => {
                        value.mediaType = '';
                        this.displayedTasks.splice(index, 1);
                    }, 1000);
                }
            } else {
                value.removing = true;
                value.slideClass = 'animate__animated animate__fadeOutDown';
                setTimeout(() => {
                    value.mediaType = '';
                    this.displayedTasks.splice(index, 1);
                }, 1000);
            }
        });
    }

    signalItemExists(workItemId: string): boolean {
        let exists = false;
        Object.values(this.workItems()).forEach(workItem => {
            if (workItem.workItemId === workItemId) {
                exists = true;
            }
        });
        return exists;
    }

    animateEnter(element: any) {
        const containerWidth =
            this.container.nativeElement.offsetWidth -
            (260 * this.displayedTasks.length + 15);
        const voice = element.id.charAt(0) === 'V' ? true : false;
        const myAnimation = this.builder.build([
            style({
                transform: voice
                    ? `translateX(-260px)`
                    : `translateX(${containerWidth}px)`,
            }),
            animate('500ms ease-in', style({ transform: 'translateX(0)' })),
        ]);

        const player = myAnimation.create(element);
        player.play();
    }

    acceptIncomingTask(task: IWorkItem) {
        if (this.checkDuplicateTask(task) === false) {
            const newTask: IIncomingTask = {
                order: this.setChannelOrder(task.primaryMediaType),
                workItemId: task.workItemId,
                conversationId: task.primaryConversationId,
                mediaType: task.primaryMediaType,
                channelType: task.primaryChannelType,
                createdAt: format(parseISO(task.createdAt), 'hh:mma'),
                createdAtActual: parseISO(task.createdAt),
                icon: this.setIcon(task.primaryMediaType),
                iconSelected: this.setSelectedIcon(task.primaryMediaType),
                selected: false,
                classSelected: this.selectClass(task.primaryMediaType),
                removing: false,
                slideClass: '',
            };
            this.displayedTasks.push(newTask);
            this.displayedTasks.sort(
                (task1, task2) => task1.order - task2.order
            );
        }
    }

    deselectOthers(workItemId: string) {
        this.selectedItem =
            this.selectedItem === workItemId ? null : workItemId;
        if (this.selectedItem !== workItemId) {
            this.selectedItem = workItemId;
        }
    }

    isSelected(workItemId: string): boolean {
        return this.selectedItem === workItemId;
    }

    setChannelOrder(mediaType: string): number {
        switch (mediaType) {
            case 'Voice':
                return TaskOrder.Voice;
            case 'Webchat':
                return TaskOrder.Webchat;
            case 'Messaging':
                return TaskOrder.Messaging;
            case 'Email':
                return TaskOrder.Email;
            default:
                return TaskOrder.Voice;
        }
    }

    setIcon(mediaType: string): string {
        switch (mediaType) {
            case 'Voice':
                return 'fa-light fa-phone task-phone';
            case 'Webchat':
                return 'fa-light fa-message task-chat';
            case 'Messaging':
                return 'fa-light fa-message task-chat';
            case 'Email':
                return 'fa-light fa-envelope task-email';
            default:
                return 'fa-light fa-share-nodes task-social';
        }
    }

    selectClass(mediaType: string): string {
        switch (mediaType) {
            case 'Voice':
                return 'selected-phone';
            case 'Webchat':
                return 'selected-chat';
            case 'Messaging':
                return 'selected-chat';
            case 'Email':
                return 'selected-email';
            default:
                return 'selected-social';
        }
    }

    setSelectedIcon(mediaType: string): string {
        switch (mediaType) {
            case 'Voice':
                return 'fa-solid fa-phone task-phone';
            case 'Webchat':
                return 'fa-solid fa-message task-chat';
            case 'Messaging':
                return 'fa-solid fa-message task-chat';
            case 'Email':
                return 'fa-solid fa-envelope task-email';
            default:
                return 'fa-solid fa-share-nodes task-social';
        }
    }
}
