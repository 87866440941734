import { IAgentSegment, IQueueSegment } from './queue';

export enum QueueActivityStatus {
    Connented = 'Connected',
    Waiting = 'Waiting',
    Completed = 'Completed',
    Abandoned = 'Abandoned',
}

export interface IQueueActivityUser {
    id: string;
    name: string;
}

export interface IQueueActivity {
    id: string;
    includeInReport: boolean;
    status: QueueActivityStatus;
    queued: Date;
    deQueued?: Date;
    started: Date;
    ended?: Date;
    connected?: Date;
    closed?: Date;
    customer: IQueueActivityUser;
    agent: IQueueActivityUser;
    connectedDuration: number;
    waitingDuration: number;
    selfServiceDuration: number;
}

export class QueueActivity implements IQueueActivity {
    queueSegment: IQueueSegment;
    agentSegment?: IAgentSegment;
    includeInReport: boolean;

    constructor(
        includeInReport: boolean,
        queueSegment: IQueueSegment,
        agentSegment?: IAgentSegment,
    ) {
        this.agentSegment = agentSegment;
        this.queueSegment = queueSegment;
        this.includeInReport = includeInReport;
    }

    get id(): string {
        return this.queueSegment.conversationId;
    }

    get status(): QueueActivityStatus {
        if (!this.queueSegment.deQueuedAt) {
            return QueueActivityStatus.Waiting;
        }

        if (this.queueSegment.deQueuedAt && !this.agentSegment) {
            return QueueActivityStatus.Abandoned;
        }

        if (this.agentSegment?.createdAt && !this.agentSegment.closedAt) {
            return QueueActivityStatus.Connented;
        }

        return QueueActivityStatus.Completed;
    }

    get queued(): Date {
        return new Date(this.queueSegment.queuedAt);
    }

    get deQueued(): Date | undefined {
        return this.queueSegment?.deQueuedAt
            ? new Date(this.queueSegment?.deQueuedAt)
            : undefined;
    }

    get connected(): Date | undefined {
        return this.agentSegment?.createdAt
            ? new Date(this.agentSegment?.createdAt)
            : undefined;
    }

    get closed(): Date | undefined {
        return this.agentSegment?.closedAt
            ? new Date(this.agentSegment?.closedAt)
            : undefined;
    }

    get started(): Date {
        return new Date(this.queueSegment.startedAt);
    }

    get ended(): Date | undefined {
        return this.queueSegment?.endedAt
            ? new Date(this.queueSegment?.endedAt)
            : undefined;
    }

    get customer(): IQueueActivityUser {
        let customer: IQueueActivityUser;

        if (this.agentSegment) {
            customer = {
                id: this.agentSegment.customer.id,
                name: `${this.agentSegment?.customer?.firstName} ${this.agentSegment?.customer.lastName}`,
            };
        } else if (this.queueSegment) {
            customer = {
                id: this.queueSegment.customer.id,
                name: `${this.queueSegment?.customer?.firstName} ${this.queueSegment?.customer.lastName}`,
            };
        } else {
            customer = {
                id: '',
                name: 'Unknown',
            };
        }

        if (customer.name.trim().length == 0) customer.name = 'Unknown';

        return customer;
    }

    get agent(): IQueueActivityUser {
        if (this.agentSegment && this.agentSegment.agents.length > 0) {
            return {
                id: this.agentSegment.agents[0].id,
                name: this.agentSegment.agents[0].name,
            };
        }

        return {
            id: '',
            name: '',
        };
    }

    get connectedDuration(): number {
        return (
            (this.closed ?? new Date()).getTime() -
            (this.connected ?? new Date()).getTime()
        );
    }

    get waitingDuration(): number {
        return (
            (this.deQueued ?? new Date()).getTime() -
            (this.queued ?? new Date()).getTime()
        );
    }

    get selfServiceDuration(): number {
        const conversationLength = Math.round(
            ((this.ended ? this.ended.getTime() : new Date().getTime()) -
                this.started.getTime()) /
                1000,
        );

        const timeInQueue = Math.round(
            ((this.deQueued ? this.deQueued.getTime() : new Date().getTime()) -
                this.queued.getTime()) /
                1000,
        );

        const timeInAgent = Math.round(
            ((this.closed ? this.closed.getTime() : new Date().getTime()) -
                (this.connected
                    ? this.connected.getTime()
                    : new Date().getTime())) /
                1000,
        );

        return conversationLength - timeInQueue - timeInAgent;
    }

    get totalTimeDuration(): number {
        return (
            (this.ended ?? new Date()).getTime() -
            (this.started ?? new Date()).getTime()
        );
    }
}
