import { Route } from '@angular/router';
import { AuthCallbackComponent } from '@core/components/auth-callback/auth-callback.component';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./dashboard/dashboard.routes').then(m => m.DashboardRoutes),
    },
    {
        path: 'performance',
        loadChildren: () =>
            import('./performance/performance.routes').then(
                m => m.PerformanceRoutes,
            ),
    },
    {
        path: 'debug',
        loadChildren: () =>
            import('./debug/debug.routes').then(m => m.DebugRoutes),
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    },
];
