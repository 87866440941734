<div class="row">
    <div class="col-12">
        <input [(ngModel)]="filterText" placeholder="Filter by name" />
    </div>
</div>

<div class="row">
    @for(queue of filteredQueues(); track queue.id) {
    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">
                    {{ queue.id }}
                </h5>
                <pre class="card-text"
                    >{{ queue | json }}{{ queue.performance | json }}</pre
                >
            </div>
        </div>
    </div>
    }
</div>

<div class="row">
    @for(businessUnit of filteredBusinessUnits(); track businessUnit.id) {
    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">
                    {{ businessUnit.id }}
                </h5>
                <pre class="card-text">{{ businessUnit | json }}</pre>
            </div>
        </div>
    </div>
    }
</div>
