<div class="h-100">
    <div class="font-small weight-medium p-0 m-0">{{ label }}</div>
    <div class="font-largest weight-medium p-0 m-0">
        {{ overall }}
        <span class="font-small weight-heavy">@if(percentage){%}</span>
    </div>
    @if(metric) {
    <ngb-progressbar [type]="state" [value]="progress" height=".3rem" />
    }
</div>
