export interface IKpiThreshold {
    slaThreshold?: number;
    maxLwtSeconds?: number;
    averageWaitTimeSeconds?: number;
    maxConversationsWaiting?: number;
}

export interface IThreshold {
    low: number;
    high: number;
    enabled: boolean;
}

export interface ITelXLKpiThreshold {
    slaThreshold: IThreshold;
    maxLongestWaitTimeSeconds: IThreshold;
    averageWaitTimeSeconds: IThreshold;
    maxConversationsWaiting: IThreshold;
}

export const TelXLKpiThresholdDefaultState: ITelXLKpiThreshold = {
    slaThreshold: {
        low: 0,
        high: 0,
        enabled: false,
    },
    averageWaitTimeSeconds: {
        low: 0,
        high: 0,
        enabled: false,
    },
    maxConversationsWaiting: {
        low: 0,
        high: 0,
        enabled: false,
    },
    maxLongestWaitTimeSeconds: {
        low: 0,
        high: 0,
        enabled: false,
    },
};

export const compareThreshold = (a: IThreshold, b: IThreshold): boolean => {
    return a.low !== b.low || a.high !== b.high || a.enabled !== b.enabled;
};
