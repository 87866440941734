import { Component, computed, effect, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveHubService } from '@services/signalr/data/live/live.hub.service';
import { AggregationsHubService } from '@services/signalr/data/aggregations/aggregations.hub.service';
import { UserService } from '@services/user/user.service';
import { QueueService } from '@services/queue/queue.service';
import { HubConnectionState } from '@microsoft/signalr';
import { BusinessUnitService } from '@services/business-unit/business-unit.service';
import { FormsModule } from '@angular/forms';
import { Queue } from '@models/queue/queue';
import { BusinessUnit } from '@models/business-unit/business-unit';

@Component({
    selector: 'debug',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './debug.component.html',
    styleUrl: './debug.component.scss',
})
export class DebugComponent implements OnInit, OnDestroy {
    isDirty = this.queueService.isDirty;
    queues = this.queueService.queues.asReadonly();
    businessUnits = this.businessUnitService.businessUnits.asReadonly();
    user = this.userService.user;
    filterText = '';

    filteredQueues = computed<Queue[]>(() =>
        this.filterText.length > 0
            ? this.queues().filter(queue =>
                  queue?.name
                      ?.toLowerCase()
                      .includes(this.filterText.toLowerCase())
              )
            : this.queues()
    );

    filteredBusinessUnits = computed<BusinessUnit[]>(() =>
        this.filterText.length > 0
            ? this.businessUnits().filter(bu =>
                  bu?.name
                      ?.toLowerCase()
                      .includes(this.filterText.toLowerCase())
              )
            : this.businessUnits()
    );

    tenantAgentStates = this.liveHubService.tenantAgentStates;
    todaysAgentReportData = this.aggregationsHubService.todaysAgentReportData;

    constructor(
        private liveHubService: LiveHubService,
        private aggregationsHubService: AggregationsHubService,
        private queueService: QueueService,
        private businessUnitService: BusinessUnitService,
        private userService: UserService
    ) {
        effect(
            () => {
                if (this.isDirty() && this.queues().length > 0) {
                    if (
                        this.liveHubService.state ===
                        HubConnectionState.Connected
                    ) {
                        this.liveHubService.stop();
                    }

                    this.liveHubService.start({
                        queueIds: this.queues().map(q => q.id),
                    });

                    if (
                        this.aggregationsHubService.state ===
                        HubConnectionState.Connected
                    ) {
                        this.aggregationsHubService.stop();
                    }

                    this.aggregationsHubService.start({
                        queueIds: this.queues().map(q => q.id),
                        agentIds: [this.user().id],
                    });

                    this.isDirty.set(false);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit() {
        this.isDirty.set(true);
    }

    ngOnDestroy() {
        this.liveHubService.stop();
        this.aggregationsHubService.stop();
    }
}
