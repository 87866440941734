import { toPercentage } from '@helpers/metric';
import { BusinessUnit } from '@models/business-unit/business-unit';
import { IMetric, IValueWithMetric } from '@models/metrics/metric';
import {
    ITelXLKpiThreshold,
    TelXLKpiThresholdDefaultState,
} from '@models/rbac/kpi';

export interface ITenant {
    id: string;
    name: string;
    kpiTelXLThreshold: ITelXLKpiThreshold;
}

export interface ITenantPerformace {
    answeredWithinSLAPercentage: IValueWithMetric;
    answeredWithinSLAPercentageMetricByBusinessUnit: IMetric[];
    waiting: IValueWithMetric;
    connected: number;
    success: number;
    complete: number;
    timedOut: number;
    abandoned: number;
    outOfSla: number;
    currentLongestWaitingTimeStamp?: Date;
    longestWaitingTimeInSeconds: IValueWithMetric;
    avgWaitingTimeInSeconds: IValueWithMetric;
}

export class Tenant implements ITenant {
    id: string;
    name: string;
    businessUnits: BusinessUnit[];
    kpiTelXLThreshold: ITelXLKpiThreshold;

    constructor(
        id: string,
        name: string,
        businessUnits: BusinessUnit[],
        kpiTelXLThreshold?: ITelXLKpiThreshold,
    ) {
        this.id = id;
        this.name = name;
        this.businessUnits = businessUnits;
        this.kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiThresholdDefaultState;
    }

    get activeBusinessUnits(): BusinessUnit[] {
        return this.businessUnits.filter(bu => bu.hasActivity);
    }

    get performance(): ITenantPerformace {
        if (this.activeBusinessUnits.length === 0) {
            return {
                answeredWithinSLAPercentageMetricByBusinessUnit: [],
                answeredWithinSLAPercentage: {
                    value: 0,
                    metric: { percent: 0, states: [] },
                },
                waiting: { value: 0, metric: { percent: 0, states: [] } },
                connected: 0,
                success: 0,
                complete: 0,
                timedOut: 0,
                abandoned: 0,
                outOfSla: 0,
                longestWaitingTimeInSeconds: {
                    value: 0,
                    metric: { percent: 0, states: [] },
                },
                avgWaitingTimeInSeconds: {
                    value: 0,
                    metric: { percent: 0, states: [] },
                },
            };
        }

        const currentLongestWaitingTimeStamp = this.activeBusinessUnits
            .map(queue => queue.performance.currentLongestWaitingTimeStamp)
            .filter((timeStamp): timeStamp is Date => timeStamp !== undefined)
            .reduce((minTimeStamp: Date | undefined, current: Date) => {
                return minTimeStamp === undefined ||
                    current.getTime() < minTimeStamp.getTime()
                    ? current
                    : minTimeStamp;
            }, undefined);

        const answeredWithinSLAPercentageMetricByBusinessUnit =
            this.activeBusinessUnits.map(
                bu => bu.performance.answeredWithinSLAPercentage.metric,
            );

        const answeredWithinSLAPercentage = Math.round(
            this.activeBusinessUnits.reduce(
                (total: number, businessUnit: BusinessUnit) => {
                    return (
                        total +
                        businessUnit.performance.answeredWithinSLAPercentage
                            .value
                    );
                },
                0,
            ) / this.activeBusinessUnits.length,
        );

        const waiting = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.waiting.value;
            },
            0,
        );

        const connected = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.connected;
            },
            0,
        );

        const success = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.success;
            },
            0,
        );

        const complete = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.complete;
            },
            0,
        );

        const outOfSla = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.outOfSla;
            },
            0,
        );

        const timedOut = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.timedOut;
            },
            0,
        );

        const abandoned = this.activeBusinessUnits.reduce(
            (total: number, businessUnit: BusinessUnit) => {
                return total + businessUnit.performance.abandoned;
            },
            0,
        );

        const longestWaitingTimeInSeconds = this.activeBusinessUnits.reduce(
            (maxLWT: number, businessUnit: BusinessUnit) => {
                return Math.max(
                    maxLWT,
                    businessUnit.performance.longestWaitingTimeInSeconds.value,
                );
            },
            0,
        );

        const avgWaitingTimeInSeconds = this.activeBusinessUnits.reduce(
            (maxAWT: number, businessUnit: BusinessUnit) => {
                return Math.max(
                    maxAWT,
                    businessUnit.performance.avgWaitingTimeInSeconds.value,
                );
            },
            0,
        );

        return {
            answeredWithinSLAPercentageMetricByBusinessUnit:
                answeredWithinSLAPercentageMetricByBusinessUnit,
            answeredWithinSLAPercentage: {
                value: answeredWithinSLAPercentage,
                metric: {
                    percent: answeredWithinSLAPercentage,
                    states: this.kpiTelXLThreshold.slaThreshold.enabled
                        ? [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.low,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold.slaThreshold.high,
                                  ),
                                  state: 'success',
                              },
                          ]
                        : [],
                },
            },
            waiting: {
                value: waiting,
                metric: this.kpiTelXLThreshold.maxConversationsWaiting.enabled
                    ? {
                          percent: toPercentage(
                              waiting /
                                  this.kpiTelXLThreshold.maxConversationsWaiting
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxConversationsWaiting.low /
                                          this.kpiTelXLThreshold
                                              .maxConversationsWaiting.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            connected: connected,
            currentLongestWaitingTimeStamp: currentLongestWaitingTimeStamp,
            longestWaitingTimeInSeconds: {
                value: longestWaitingTimeInSeconds,
                metric: this.kpiTelXLThreshold.maxLongestWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              longestWaitingTimeInSeconds /
                                  this.kpiTelXLThreshold
                                      .maxLongestWaitTimeSeconds.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            avgWaitingTimeInSeconds: {
                value: avgWaitingTimeInSeconds,
                metric: this.kpiTelXLThreshold.averageWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              (avgWaitingTimeInSeconds ?? 0) /
                                  this.kpiTelXLThreshold.averageWaitTimeSeconds
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this.kpiTelXLThreshold
                                          .averageWaitTimeSeconds.low /
                                          this.kpiTelXLThreshold
                                              .averageWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            success: success,
            complete: complete,
            outOfSla: outOfSla,
            timedOut: timedOut,
            abandoned: abandoned,
        };
    }
}
