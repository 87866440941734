import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    INavLink,
    collapsedNavbarLinks,
    navbarLinks,
} from '@models/menu/links';
import { SideNavItemComponent } from '@base/components/side-nav/item/side-nav-item.component';
import { ReplaceSpacesPipe } from '@pipes/replace-spaces/replace-spaces.pipe';
import { UserService } from '@services/user/user.service';
import { AuthService } from '@api/auth/auth.service';

@Component({
    selector: 'side-nav',
    standalone: true,
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
    imports: [
        CommonModule,
        RouterModule,
        SideNavItemComponent,
        ReplaceSpacesPipe,
    ],
})
export class SideNavComponent {
    @Input({ required: true }) isCollapsed!: boolean;
    @Output() toggleCollapse: EventEmitter<void> = new EventEmitter();

    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) {}

    onToggleCollapse() {
        this.toggleCollapse.emit();
    }

    get links(): INavLink[] {
        return this.isCollapsed ? collapsedNavbarLinks : navbarLinks;
    }

    canRender(permission?: string): boolean {
        return permission ? this.userService.hasPermission(permission) : true;
    }

    logout() {
        this.authService.logout();
    }
}
